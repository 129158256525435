import { Form, Input, Table } from "antd";
import { useLocalizationContext } from "../../../context/LocalizationContext";
import { Text } from "../../DesignSystem";

const DEFAULT_DATA = [
  { key: 1 },
  { key: 2 },
  { key: 3 },
  { key: 4 },
  { key: 5 },
];

export const ForeignResearcherTable = () => {
  const { t } = useLocalizationContext();

  const columns = [
    {
      title: <Text type="normal bold" text={t("LABEL.number")} />,
      dataIndex: "key",
      key: "key",
      align: "center",
      render: (text) => <Text type="normal" text={text} />,
    },
    {
      title: (
        <Text type="normal bold" text={t("LABEL.foreign_researcher_name")} />
      ),
      dataIndex: "fname",
      key: "fname",
      align: "center",
      render: (text, record, index) => (
        <Form.Item
          name={["foreigns", index, "fname"]}
          style={{ marginBottom: 0 }}
        >
          <Input />
        </Form.Item>
      ),
    },
    {
      title: <Text type="normal bold" text={t("LABEL.university")} />,
      dataIndex: "funi",
      key: "funi",
      align: "center",
      render: (text, record, index) => (
        <Form.Item
          name={["foreigns", index, "funi"]}
          style={{ marginBottom: 0 }}
        >
          <Input />
        </Form.Item>
      ),
    },
    {
      title: <Text type="normal bold" text={t("LABEL.country")} />,
      dataIndex: "fcountry",
      key: "fcountry",
      align: "center",
      render: (text, record, index) => (
        <Form.Item
          name={["foreigns", index, "fcountry"]}
          style={{ marginBottom: 0 }}
        >
          <Input />
        </Form.Item>
      ),
    },
  ];

  return (
    <Table
      dataSource={DEFAULT_DATA}
      columns={columns}
      pagination={false}
      bordered
      rowKey={"key"}
    />
  );
};
