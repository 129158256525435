import dayjs from "dayjs";
import { useLocalizationContext } from "../../context/LocalizationContext";
import { BaseButton, Box, Text } from "../DesignSystem";
import { useGoToRoute } from "./useGoToRoute";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import apiDeleteRequest from "../api/apiDeleteRequest";
import apiExportData from "../api/apiExportData";
import { useConFirmModal } from "./useConFirmModal";
import { useNotification } from "./useNotification";
import { mutate } from "swr";
import { requestListApi } from "../api/useGetRequestList";

export const useColumns = () => {
  const { t } = useLocalizationContext();
  const { goToEditForm } = useGoToRoute();
  const { showConfirmDelete } = useConFirmModal();
  const { notiUpdateSuccess } = useNotification();
  // const { exportData } = useExportDataContext();

  const deleteRequest = async (rid) => {
    const { data } = await apiDeleteRequest(rid);
    if (data) {
      notiUpdateSuccess();
      mutate(requestListApi);
    }
  };

  const exportData = async (rid) => {
    console.log("useExportData clicked, rid: " + rid);
    await apiExportData(rid);
  };

  const statusText = (status) => {
    if (status) {
      const lowercase = status?.toLowerCase(); // Convert to lowercase
      const uppercaseFirstLetter =
        lowercase.charAt(0).toUpperCase() + lowercase.slice(1); // Convert first letter to uppercase
      return uppercaseFirstLetter;
    }
  };

  const columns = (isStudentMode) => [
    {
      title: <Text type="normal bold" text={t("LABEL.form_number")} />, // column rid
      dataIndex: "rid",
      key: "rid",
      render: (text) => <Text type="normal" text={text} />, // text คือ value ของ dataIndex และ key
    },
    {
      title: <Text type="normal bold" text={t("LABEL.form_type")} />, // column pub type
      dataIndex: "pubtype",
      key: "pubtype",
      render: (text) => {
        const checkPubType =
          text === "CON"
            ? t("LABEL.conferences")
            : text === "JOU"
            ? t("LABEL.journal")
            : t("LABEL.others");

        return <Text type="normal" text={checkPubType || "-"} />;
      },
    },
    {
      title: <Text type="normal bold" text={t("LABEL.name")} />, // column ชื่อ+รหัสนักศึกษา
      dataIndex: "sname",
      key: "sname",
      render: (text, record) => (
        <Box direction="column">
          <Text type="normal" text={text || "-"} />
          <Text type="normal" text={record?.sid || "-"} />
        </Box>
      ),
    },
    {
      title: <Text type="normal bold" text={t("LABEL.status")} />, // column status
      dataIndex: "rstatus",
      key: "rstatus",
      render: (text) => {
        const isPending = text === "PENDING";
        const isRejected = text === "REJECTED";
        const statusColor = isPending
          ? "var(--main-orange)"
          : isRejected
          ? "var(--main-red)"
          : "var(--main-green)";

        return (
          <Text type="normal" text={statusText(text)} color={statusColor} />
        );
      },
    },
    {
      title: <Text type="normal bold" text={t("LABEL.note")} />, // column note
      dataIndex: "rcomment",
      key: "rcomment",
      render: (text) => (
        <Text
          type="normal"
          text={text ? <div dangerouslySetInnerHTML={{ __html: text }} /> : "-"}
          style={{ whiteSpace: "pre-wrap" }}
        />
      ),
    },
    {
      title: <Text type="normal bold" text={t("LABEL.timestamp")} />, // column last update
      dataIndex: "lastmodifieddate",
      key: "lastmodifieddate",
      render: (text) => (
        <Text
          type="normal"
          text={text ? dayjs(text).format("DD/MM/YYYY HH:mm") : "-"}
        />
      ),
    },
    {
      dataIndex: "action", // column ปุ่ม action
      key: "action",
      align: "end",
      fixed: "right",
      render: (text, record) => {
        const isApproved = record?.rstatus === "APPROVED";
        const buttonType = isApproved || !isStudentMode ? "default" : "primary";

        const checkButtonText = () => {
          // เช็คตัวหนังสือของปุ่ม
          if (isStudentMode) {
            if (isApproved) return t("LABEL.view");
            else return t("LABEL.edit");
          } else {
            return t("LABEL.view");
          }
        };

        return (
          <Box align="center" justify="flex-end" gap={16}>
            <BaseButton // ปุ่มหลัก
              type={buttonType}
              icon={!isApproved && isStudentMode && <EditOutlined />}
              onClick={() => goToEditForm(record?.rid)}
            >
              {checkButtonText()}
            </BaseButton>
            {isStudentMode && ( // ปุ่ม delete ที่จะเห็นได้เฉพาะนักศึกษา
              <BaseButton
                type="primary"
                danger
                icon={<DeleteOutlined />}
                onClick={() =>
                  showConfirmDelete({ onOk: () => deleteRequest(record?.rid) })
                }
              >
                {t("LABEL.delete")}
              </BaseButton>
            )}
            {!isStudentMode && (
              <BaseButton // Export เอกสาร
                type={buttonType}
                onClick={() => exportData(record?.rid)}
                disabled={!isApproved}
              >
                {t("LABEL.export")}
              </BaseButton>
            )}
          </Box>
        );
      },
    },
  ];

  return { columns };
};
