import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
  html {
    --white: #ffffff;
    --black: #000000;
    --main-orange: #f7941d;
    --main-red: #ff4f4f;
    --main-green: #35cb00;
    --black-400: #1e1e1e;
    --black-300: #2d2d2d;
    --black-200: #343434;
    --black-100: #616161;
    --gray-300: #828282;
    --gray-200: #d7d7d7;
    --gray-100: #f2f2f2;
    --blue: #2f82ff;
    --orange-secondary: #ffedd7;
    --red-secondary: #ffe8ec;
    --blue-secondary: #e4f1ff;
  
    --border-radius: 8px;
    --box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.25);
  }
`;

export default GlobalStyles;
