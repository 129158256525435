import React, { Suspense, memo, useCallback, useEffect, useState } from "react";
import { Button, Col, Layout, Menu, Row, Select, Avatar, Spin } from "antd";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { useMenuSider } from "./hooks/useMenuSider";
import PageRoutes from "./PageRoutes";
import { Box, Text, BaseButton } from "./DesignSystem";
import { useLanguageMenu } from "./hooks/useLanguageMenu";
import { useLocalizationContext } from "../context/LocalizationContext";
import { useLocation, useNavigate } from "react-router-dom";
import { useNotificationContext } from "../context/NotificationContext";
import { useUserDataContext } from "../context/UserDataContext";
import { UserOutlined } from "@ant-design/icons";
import axios from "axios";

const { Header, Sider, Content } = Layout;

export default memo(function LayoutPage() {
  // หน้าที่เอาไว้จัดการ layout ของ web
  const [selectedMenu, setSelectedMenu] = useState(""); // เก็บเมนูที่เลือก
  const [collapsed, setCollapsed] = useState(false); // เก็บสถานะยืดหดเมนู side bar
  const { DEFAULT_MENU } = useMenuSider();
  const { LANGUAGE_MENU } = useLanguageMenu();
  const navigate = useNavigate();
  const location = useLocation();
  const { t, changeLanguage, getCurrentLng } = useLocalizationContext();
  const { contextHolder } = useNotificationContext();
  const { userData, handleLogout } = useUserDataContext();
  const jwt = localStorage.getItem("token");

  const axiosGlobalConfig = useCallback(() => {
    axios.interceptors.request.use(function (config) {
      config.baseURL = `${process.env.REACT_APP_API_PATH}`;
      if (jwt) {
        config.headers.Authorization = `Bearer ${jwt}`;
      }
      return config;
    });
  }, [jwt]);

  useEffect(() => {
    axiosGlobalConfig();
  }, [axiosGlobalConfig]);

  useEffect(() => {
    // ใช้ set path ปัจจุบันเมื่อมีการ refresh จะไม่กลับไปหน้าแรก
    if (location?.pathname === "/" && jwt) {
      navigate({ pathname: `/form-status`, search: `?lng=${getCurrentLng()}` });
    }
    if (location?.pathname) {
      setSelectedMenu(location?.pathname?.replace("/", ""));
    }
  }, [getCurrentLng, location?.pathname, navigate, jwt]);

  if (!jwt) {
    return (
      <Box justify="center" align="center" style={{height: "100dvh"}}>
        <Spin />
      </Box>
    );
  }

  return (
    <Suspense fallback="">
      {contextHolder}
      <Layout style={{ height: "100vh" }}>
        <SiderStyle
          breakpoint="lg" // กำหนดขนาดจอต่ำสุดที่จะให้ sidebar หด
          collapsedWidth="0" // กำหนดความกว้างเมื่อ sidebar หด
          width={250} // ความกว้างของ sidebar
          collapsible // กำหนดให้สามารถกดย่อขยาย sidebar
          collapsed={collapsed} // เช็คการย่อหดถ้า true จะหด ถ้า false จะขยาย
          onCollapse={(value) => setCollapsed(value)} // จัดการการย่อหดของ sidebar
          trigger={null}
        >
          {/* เผื่อใส่ logo */}
          <div style={{ height: "60px" }} />
          {/* ---------- */}
          <Menu
            mode="inline"
            selectedKeys={[selectedMenu]}
            defaultOpenKeys={["academicPublishing"]}
            items={DEFAULT_MENU}
            onSelect={(value) => {
              setSelectedMenu(value.key);
              navigate({
                pathname: `/${value.key}`,
                search: `?lng=${getCurrentLng()}`,
              });
            }}
          />
        </SiderStyle>
        <Layout>
          <HeaderStyle style={{ padding: "0 16px" }}>
            <Box
              justify="space-between"
              align="center"
              style={{ height: "100%" }}
            >
              <Button
                type="text"
                icon={
                  collapsed ? (
                    <MenuUnfoldOutlined style={{ color: "white" }} />
                  ) : (
                    <MenuFoldOutlined style={{ color: "white" }} />
                  )
                }
                onClick={() => setCollapsed(!collapsed)}
                style={{
                  fontSize: "16px",
                }}
              />
              <Box gap={16} align="center">
                <Select // dropdown เปลี่ยนภาษา
                  value={getCurrentLng()} // getCurrentLng() คือตัวรับภาษาปัจจุบันของระบบ
                  onChange={(val) => {
                    changeLanguage(val);
                    navigate({
                      pathname: location?.pathname,
                      search: `?lng=${val}`,
                    });
                  }}
                  options={LANGUAGE_MENU}
                />
                {/* ---------------- user profile --------------- */}
                <UserBox gap={8} align="center">
                  <Avatar size={40} shape="circle" icon={<UserOutlined />} />
                  <Box direction="column" align="start">
                    <Text
                      type="normal"
                      text={`${
                        userData?.itaccount_name || "data_error"
                      }@cmu.ac.th`}
                      color="white"
                    />
                    <Text
                      type="small"
                      text={`${
                        userData?.first_name?.[
                          getCurrentLng() === "en" ? "en_US" : "th_TH"
                        ]
                      } ${
                        userData?.last_name?.[
                          getCurrentLng() === "en" ? "en_US" : "th_TH"
                        ]
                      }`}
                      color="white"
                    />
                  </Box>
                </UserBox>
                <BaseButton type="primary" danger onClick={handleLogout}>
                  {t("LABEL.logout")}
                </BaseButton>
                {/* --------------------------------------------- */}
              </Box>
            </Box>
          </HeaderStyle>
          <Content style={{ padding: "24px 24px 100px", overflow: "auto" }}>
            <Row>
              <Col span={1} />
              <Col span={22}>
                <PageRoutes />
              </Col>
              <Col span={1} />
            </Row>
          </Content>
        </Layout>
      </Layout>
    </Suspense>
  );
});

const UserBox = styled(Box)`
  padding: 0 16px;
  border-left: 1px solid var(--black-400);
`;

const HeaderStyle = styled(Header)`
  height: 60px;
  background-color: var(--black-300);
`;

const SiderStyle = styled(Sider).withConfig({
  shouldForwardProp: (prop) => prop !== "",
})`
  &.ant-layout-sider {
    background-color: var(--black-300);
    box-shadow: var(--box-shadow);
  }
  .ant-menu-light {
    background-color: var(--black-300);
  }
  .ant-menu-submenu-title {
    color: var(--white);
  }
  .ant-menu-submenu-title:hover {
    color: var(--white) !important;
    background-color: var(--main-orange) !important;
  }
  .ant-menu-light .ant-menu-submenu-selected > .ant-menu-submenu-title {
    color: var(--white);
  }
  li.ant-menu-item.ant-menu-item-active.ant-menu-item-only-child:hover {
    background-color: var(--main-orange);
    color: var(--white);
  }
  li.ant-menu-item.ant-menu-item-only-child {
    color: var(--white);
  }
  li.ant-menu-item.ant-menu-item-selected.ant-menu-item-only-child {
    background-color: var(--main-orange);
    color: var(--white);
  }
  .ant-menu-light.ant-menu-inline .ant-menu-sub.ant-menu-inline {
    background-color: var(--black-400);
  }
`;
