import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import translationEN from "./locale/en/translation.json";
import translationTH from "./locale/th/translation.json";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translations: translationEN,
      },
      th: {
        translations: translationTH,
      },
    },
    detection: {
      order: [
        "querystring",
        "localStorage",
        "cookie",
        "sessionStorage",
        "navigator",
        "htmlTag",
        "path",
        "subdomain",
      ],
    },
    fallbackLng: "en",
    debug: false, // set false when you want to close console.log
    ns: ["translations"],
    defaultNS: "translations",
    keySeparator: ".",
    interpolation: {
      escapeValue: false, // no need for react
    },
  });

export default i18n;
