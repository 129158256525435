import { useLocalizationContext } from "../../context/LocalizationContext";

export const useFieldValidate = () => { // ใช้สำหรับรวม validate
  const { t } = useLocalizationContext();
  const requireField = { // require field
    required: true,
    message: t("VALIDATE.please_fill_out"),
  };

  const requireSelect = { // require select radio
    required: true,
    message: t("VALIDATE.please_select_answer"),
  };

  const requireUpload = { // require upload file
    required: true,
    message: t("VALIDATE.please_upload_file"),
  };

  const requireEmailFormat = { // valiedate email format
    type: "email",
    message: t("VALIDATE.email_is_not_a_valid_email"),
  };

  const requireRejectNote = { // require reject note
    required: true,
    message: t("VALIDATE.please_fill_in_reject_note"),
  };

  return {
    requireField,
    requireSelect,
    requireUpload,
    requireEmailFormat,
    requireRejectNote
  };
};
