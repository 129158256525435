import { memo } from "react";
import { Form, Input } from "antd";
import { Text } from "../../DesignSystem";
import { useLocalizationContext } from "../../../context/LocalizationContext";
import { useFieldValidate } from "../../hooks/useFieldValidate";

export const OtherForm = memo(({ isViewOnly }) => {
  const { t } = useLocalizationContext();
  const { requireField } = useFieldValidate();
  // render others form
  return (
    <Form.Item
      name="othdocname"
      label={
        <Text
          type="normal bold"
          text={`3.1 ` +t("LABEL.publicly_published")}
        />
      }
      required={false}
      rules={[requireField]}
    >
      <Input style={{ width: "50%" }} placeholder="" disabled={isViewOnly} />
    </Form.Item>
  );
});
