import dayjs from "dayjs";
import { useCallback, useMemo } from "react";

import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";

dayjs.extend(weekday);
dayjs.extend(localeData);

export const useCreateForm = () => {
  // ใช้สำหรับจะดการข้อมูลในหน้า create
  const handleSetDefaultFromApi = useCallback((requestData) => {
    // set default data เมื่อเข้ามา edit โดยใช้ requestData จากที่หลักบ้านส่งมา
    return {
      sid: requestData?.sid || "",
      sname: requestData?.sname || "",
      smajor: requestData?.smajor || "",
      seduplan: requestData?.seduplan || "",
      semail: requestData?.semail || "",
      stelno: requestData?.stelno || "",
      ttitle: requestData?.ttitle || "",
      etitle: requestData?.etitle || "",
      author: requestData?.author || "",
      aname: requestData?.aname || "",
      anameco_1: requestData?.anameco_1 || "",
      anameco_2: requestData?.anameco_2 || "",
      conname: requestData?.conname || "",
      condestination: requestData?.condestination || "",
      condate: requestData?.conbegindate
        ? [dayjs(requestData?.conbegindate), dayjs(requestData?.conenddate)]
        : null,
      isfirst: requestData?.isfirst ? "true" : "false",
      hasadv: requestData?.hasadv ? "true" : "false",
      hasforeign: requestData?.hasforeign ? "true" : "false",
      conlink: requestData?.conlink,
      fileconcover: requestData?.fileconcover || "",
      filecontable: requestData?.filecontable || "",
      fileconcert: requestData?.fileconcert || "",
      fileconfullpaper: requestData?.fileconfullpaper || "",
      filejcover: requestData?.filejcover || "",
      filejtable: requestData?.filejtable || "",
      filejfullpaper: requestData?.filejfullpaper || "",
      filejaccept: requestData?.filejaccept || "",
      othdocname: requestData?.othdocname || "",
      fileothdoc: requestData?.fileothdoc || "",
      jpage: requestData?.jpage || "",
      jsubmitdate: dayjs(requestData?.jsubmitdate) || "",
      isjaccepted: requestData?.isjaccepted ? "true" : "false",
      jaccepteddate: dayjs(requestData?.jaccepteddate) || "",
      jname: requestData?.jname || "",
      jno: requestData?.jno || "",
      jvol: requestData?.jvol || "",
      jyear: requestData?.jyear || "",
      jtci: requestData?.jtci || "",
      jtciother: requestData?.jtciother || "",
      jdb: requestData?.jdb || "",
      jdbother: requestData?.jdbother || "",
      jquartile: requestData?.jquartile || "",
      jispredatory: requestData?.jispredatory ? "true" : "false" || "",
      rcomment: requestData?.rcomment || "",
      jtype: requestData?.jtype || "",
      jlink: requestData?.jlink || "",
      othlink: requestData?.othlink || "",
    };
  }, []);

  const getDefaultValue = useMemo(() => {
    // default value สำหรับตอนสร้างใหม่
    return {
      pubtype: "CON", // ให้ default select ไว้ที่ conferences
      contype: "NAT", // ให้ default select ไว้ที่ national
      jtype: "NAT", // ให้ default select ไว้ที่ national
    };
  }, []);

  return { getDefaultValue, handleSetDefaultFromApi };
};
