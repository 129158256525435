import { Modal } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { useLocalizationContext } from "../../context/LocalizationContext";

const { confirm } = Modal;

export const useConFirmModal = () => {
  const { t } = useLocalizationContext();

  const showConfirmReset = ({ onOk }) => { // คอนเฟิร์มเมื่อมีการกด reset
    confirm({
      title: t("LABEL.areYouWantToResetData"),
      icon: <ExclamationCircleFilled />,
      centered: true,
      content: t("LABEL.areYouWantToResetDataDesc"),
      okText: t("LABEL.yes"),
      cancelText: t("LABEL.no"),
      okButtonProps: {
        style: { backgroundColor: "var(--main-orange)" },
      },
      onOk: () => onOk(),
    });
  };

  const showConfirmSubmit = ({ onOk }) => { // คอนเฟิร์มเมื่อมีการกด submit
    confirm({
      title: t("LABEL.areYouSureToSubmit"),
      icon: <ExclamationCircleFilled />,
      centered: true,
      okText: t("LABEL.yes"),
      cancelText: t("LABEL.no"),
      okButtonProps: {
        style: { backgroundColor: "var(--main-orange)" },
      },
      onOk: () => onOk(),
    });
  };

  const showConfirmReject = ({ onOk }) => { // คอนเฟิร์มเมื่อมีการกด reject
    confirm({
      title: t("LABEL.areYouSureToReject"),
      icon: <ExclamationCircleFilled style={{ color: "var(--main-red)" }} />,
      centered: true,
      okText: t("LABEL.yes"),
      cancelText: t("LABEL.no"),
      okButtonProps: {
        style: { backgroundColor: "var(--main-red)" },
      },
      onOk: () => onOk(),
    });
  };

  const showConfirmApprove = ({ onOk }) => { // คอนเฟิร์มเมื่อมีการกด approve
    confirm({
      title: t("LABEL.areYouSureToApprove"),
      icon: <ExclamationCircleFilled style={{ color: "var(--main-green)" }} />,
      centered: true,
      okText: t("LABEL.yes"),
      cancelText: t("LABEL.no"),
      okButtonProps: {
        style: { backgroundColor: "var(--main-green)" },
      },
      onOk: () => onOk(),
    });
  };

  const showConfirmDelete = ({ onOk }) => { // คอนเฟิร์มเมื่อมีการกด delete
    confirm({
      title: t("LABEL.areYouSureToDelete"),
      icon: <ExclamationCircleFilled style={{ color: "var(--main-red)" }} />,
      centered: true,
      okText: t("LABEL.yes"),
      cancelText: t("LABEL.no"),
      okButtonProps: {
        style: { backgroundColor: "var(--main-red)" },
      },
      onOk: () => onOk(),
    });
  };

  return {
    showConfirmReset,
    showConfirmSubmit,
    showConfirmReject,
    showConfirmApprove,
    showConfirmDelete
  };
};
