import { memo, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { BaseButton, Box, Text } from "../DesignSystem";
import { useLocalizationContext } from "../../context/LocalizationContext";
import { useNotification } from "../hooks/useNotification";
import apiGetFileUrl from "../api/apiGetFileUrl";

const MAX_SIZE = 10000000; // 10 MB
const FILE_TYPE = "pdf";

export default memo(function UploadFile({
  onChange,
  name,
  isViewOnly,
  defaultFile,
}) {
  // componenet upload
  const { notiUploadMaximumSize, notiUploadWrongType } = useNotification();
  const [fileData, setFileData] = useState(null);
  const { t } = useLocalizationContext();
  const fileRef = useRef();

  useEffect(() => {
    // ถ้ามี defaultFile ให้ set fileData เป็น defaultFile ใช้กรณีเข้ามา view/edit จะขึ้น default ไฟล์ที่เคย upload
    if (defaultFile) {
      setFileData(defaultFile);
    }
  }, [defaultFile]);

  const uploadPDF = async (e) => {
    // func จัดการ file upload
    const file = e?.target?.files[0];
    const fileType = file?.name?.split(".").pop().toLowerCase();
    if (file?.size === MAX_SIZE) {
      // ถ้าขนาดไฟล์เกิน 10mb จะอัปไม่ผ่านขึ้น noti เตือน
      notiUploadMaximumSize();
    } else if (fileType !== FILE_TYPE) {
      // ถ้าไฟล์ไม่ใช่ pdf จะอัปไม่ผ่านขึ้น noti เตือน
      notiUploadWrongType();
    } else {
      // อัปผ่านแล้วให้ยิงไป get file url จากหลังบ้าน
      const { data } = await apiGetFileUrl(file, name);
      if (data) {
        setFileData(e?.target?.files[0]);
        onChange?.({ [name]: data?.url });
      } else {
        return "";
      }
    }
  };

  const openFileInNewTab = () => {
    // func สำหรับเปิดไฟล์
    let fileURL = "";
    if (typeof fileData !== "string") {
      try {
        fileURL = URL.createObjectURL(fileData);
      } catch (e) {
        console.log(e);
        fileURL = URL.createObjectURL(fileRef.current.files[0]);
      }
      window.open(fileURL, "_blank").focus();
    } else {
      return window.open(fileData);
    }
  };

  return (
    <UploadBox>
      <input
        type="file"
        hidden
        accept=".pdf"
        ref={fileRef}
        onChange={(e) => uploadPDF(e)}
      />
      {!isViewOnly && ( // ถ้าเป็น create/edit จะขึ้น choose file เพื่อกดอัปโหลดได้
        <BaseButton onClick={() => fileRef.current.click()}>
          {t("LABEL.choose_file")}
        </BaseButton>
      )}
      {fileData && ( // ถ้ามี fileData แล้วขึ้นลิงก์ชื่อไฟล์ให้กดเปิดได้
        <Text
          type="link"
          text={
            `${fileData?.name || "PDF"} (${t("LABEL.click_to_view")})`
          }
          onClick={openFileInNewTab}
        />
      )}
      {!isViewOnly && ( // ถ้าเป็น create/edit จะขึ้นคำอธิบายอัปโหลดไฟล์
        <Text type="small" text={t("LABEL.support_file")} />
      )}
    </UploadBox>
  );
});

const UploadBox = styled(Box)`
  width: 100%;
  border: 1px solid var(--gray-200);
  background-color: var(--gray-100);
  padding: 16px;
  border-radius: var(--border-radius);
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 8px;
`;
