import { memo } from "react";
import { Card, Form, Input, Radio } from "antd";
import { BaseButton, Box, Text } from "../DesignSystem";
import { useLocalizationContext } from "../../context/LocalizationContext";
import { useFieldValidate } from "../hooks/useFieldValidate";

export default memo(function AdminActionCard({
  // componenet card สำหรับแอดมินที่เอาไว้ทำการ approve reject
  rstatus,
  onSubmit,
  latestComment,
}) {
  const { t } = useLocalizationContext();
  const { requireRejectNote } = useFieldValidate();

  return (
    <Card bodyStyle={{ padding: "16px" }}>
      {latestComment && ( // ถ้ามี latestComment ส่งมาจะขึ้นกล่องแสดง note ล่าสุดที่เคยส่งไป
        <Box direction="column" gap={16} style={{ marginBottom: 24 }}>
          <Box gap={8}>
            <Text type="normal bold" text={`${t("LABEL.latestResult")}:`} />
            <Text
              type="normal bold"
              color="var(--main-red)"
              text={t("LABEL.reject")}
            />
          </Box>
          <Input.TextArea
            rows={5}
            defaultValue={latestComment}
            placeholder={t("LABEL.rejectNote")}
            style={{ resize: "none" }}
            disabled
          />
        </Box>
      )}
      <Form.Item name="rstatus">
        <Radio.Group>
          <Box direction="column" gap={16}>
            <Radio value="APPROVED">
              <Text type="normal bold" text={t("LABEL.approve")} />
            </Radio>
            <Radio value="REJECTED">
              <Text type="normal bold" text={t("LABEL.reject")} />
            </Radio>
          </Box>
        </Radio.Group>
      </Form.Item>
      {rstatus === "REJECTED" && ( // ถ้าเลือก reject จะขึ้นกล่องให้กรอก note
        <Form.Item
          name="rcomment"
          rules={[requireRejectNote]}
          style={{ marginTop: "16px" }}
        >
          <Input.TextArea
            rows={5}
            placeholder={t("LABEL.rejectNote")}
            style={{ resize: "none" }}
          />
        </Form.Item>
      )}
      <BaseButton type="primary" block disabled={!rstatus} onClick={onSubmit}>
        {t("LABEL.confirm")}
      </BaseButton>
    </Card>
  );
});
