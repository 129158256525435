import axios from 'axios';

const apiExportData = async (rid) => {
  try {
    const response = await axios.get(`/api/export?rid=${rid}`, {
      responseType: 'blob', // Set the response type to 'blob'
    });

    // Check if the response status is OK
    if (response.status === 200) {
      const blob = new Blob([response.data], { type: 'application/zip' });

      // Create a date object and add 7 hours to it for the Thai timezone (ICT)
      const utcDate = new Date();
      utcDate.setHours(utcDate.getHours() + 7);

      // Format the date string in the desired format
      const formattedDate = utcDate.toISOString().replace(/[-T:.Z]/g, '-');

      // Create a filename based on the rid and the formatted date
      const filename = `R${rid}_${formattedDate}.zip`;

      // Create a temporary anchor element and initiate the download with the new filename
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();

      // Clean up the temporary anchor element and URL object
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);

      return { data: null, error: null }; // No data to return, as the file is downloaded directly
    } else {
      // Handle non-200 responses here if needed
      return { data: null, error: 'Non-200 response' };
    }
  } catch (error) {
    // Handle any errors that occurred during the request
    return { data: null, error: error.message };
  }
};

export default apiExportData;
