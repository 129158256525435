import { useNavigate } from "react-router-dom";
import { useLocalizationContext } from "../../context/LocalizationContext";

export const useGoToRoute = () => { // ใช้สำหรับรวม func ที่จะพาไป route ต่างๆ
  const { getCurrentLng } = useLocalizationContext();
  const navigate = useNavigate();

  const goToCreateForm = () => { // ไปหน้า create form
    navigate({
      pathname: `/create-form`,
      search: `?lng=${getCurrentLng()}`
    });
  };

  const goToFormStatus = () => { // ไปหน้า form status
    navigate({
      pathname: `/form-status`,
      search: `?lng=${getCurrentLng()}`
    });
  };

  const goToEditForm = (requestId) => { // ไปหน้า edit ต้องส่ง requestId มาด้วย
    navigate({
      pathname: `/create-form`,
      search: `?lng=${getCurrentLng()}&request=${requestId}`
    });
  };

  return { goToCreateForm, goToEditForm, goToFormStatus };
};
