import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { BaseButton, Box, Text } from "../DesignSystem";
import { useLocalizationContext } from "../../context/LocalizationContext";
import { Empty, Input, Pagination, Select, Spin, Table } from "antd";
import { useGoToRoute } from "../hooks/useGoToRoute";
import { useGetRequestList } from "../api/useGetRequestList";
import { useNotification } from "../hooks/useNotification";
import { useColumns } from "../hooks/useColumns";
import { useSelectOption } from "../hooks/useSelectOption";
import { PlusOutlined, ReloadOutlined } from "@ant-design/icons";
import Overview from "../Components/Overview";
import { useUserDataContext } from "../../context/UserDataContext";
import { useNavigate } from "react-router-dom";

const { Search } = Input;

export default memo(function FormStatus() {
  const [query, setQuery] = useState({
    // เอาไว้จัดการ state ที่จะส่ง query ไป get ข้อมูล
    page: 1,
    total: 0,
    search: "",
    pubtype: "",
    smajor: "",
    rstatus: "",
    searchType: "sname",
    pageSize: 10,
  });
  const { goToCreateForm } = useGoToRoute(); // เอาไว้รวม func ที่จะพาไป route ต่างๆ
  const { t } = useLocalizationContext(); // ตัวเรียกใช้แปลภาษา
  const { notiSystemError } = useNotification(); // เอาไว้รวม notification
  const { columns } = useColumns(); // column ของ table ในหน้านี้
  const { searchOptions, formTypeOptions, formMajorOptions, statusOptions } =
    useSelectOption(); // ตัวที่เอาไว้รวม option ของ dropdown
  const {
    data: requestList, // get request list
    isLoading,
    error,
  } = useGetRequestList({
    pageSize: query?.pageSize,
    page: query?.page,
    pubtype: query?.pubtype,
    smajor: query?.smajor,
    rstatus: query?.rstatus,
    searchtype: query?.search ? query?.searchType : "",
    search: query?.search,
  });
  const { isStudent } = useUserDataContext();
  const navigate = useNavigate();

  useEffect(() => {
    // เช็คว่าเมื่อมีข้อมูล requestList และ isLoading เป็น false ให้ set query ในส่วนของ total ตามที่หลังบ้านส่งมา
    if (requestList && !isLoading) {
      setQuery((prev) => ({ ...prev, total: requestList?.totalCount }));
    }
  }, [isLoading, requestList]);

  useEffect(() => {
    // เช็คว่าเมื่อ get request list เกิด error ให้แสดง notification error
    if (error && !isLoading) {
      notiSystemError();
    }
  }, [error, notiSystemError, t, isLoading]);

  const handleChangeSearchType = (value) => {
    // func ที่เอาไว้จัดการ search type
    setQuery((prev) => ({ ...prev, searchType: value }));
  };

  const onChangePage = (page) => {
    // func ที่เอาไว้จัดการการเปลี่ยน page
    setQuery((prev) => ({ ...prev, page: page }));
  };

  const onChangePageSize = (current, pageSize) => {
    // func ที่เอาไว้จัดการการเปลี่ยน page size
    setQuery((prev) => ({ ...prev, pageSize: pageSize }));
  };

  const onChangeSearch = useCallback((val) => {
    // func ที่เอาไว้จัดการ search value ซึ่งเมื่อมีการ search ให้ set page เป็น 1 ตลอด
    setQuery((prev) => ({ ...prev, search: val, page: 1 }));
  }, []);

  const onChangeSearchFormMajor = (value) => {
    // func ที่เอาไว้จัดการ filter ด้วย Major
    setQuery((prev) => ({ ...prev, smajor: value }));
  };

  const onChangeSearchFormType = (value) => {
    // func ที่เอาไว้จัดการ filter ด้วย pub type
    setQuery((prev) => ({ ...prev, pubtype: value }));
  };

  const onChangeSearchStatus = (value) => {
    // func ที่เอาไว้จัดการ filter ด้วย status
    setQuery((prev) => ({ ...prev, rstatus: value }));
  };

  const adminConfig = useMemo(() => {
    // เอาไว้เช็คการแสดงผลระหว่างนักศึกษากับแอดมิน
    if (isStudent) {
      // ถ้าเป็นนักศึกษาจะเห็นแค่ total request กับปุ่ม create form
      return (
        <Box justify="space-between" align="center">
          <Text
            type="normal bold"
            text={`${t("LABEL.total")}: ${query?.total}`}
          />
          <BaseButton
            type="primary"
            onClick={goToCreateForm}
            icon={<PlusOutlined />}
          >
            {t("MENU.create_form")}
          </BaseButton>
        </Box>
      );
    } else {
      // แสดงผลในส่วนของแอดมิน
      return (
        <Box direction="column" gap={16}>
          {/* overview สรุปจำนวน request ทั้งหมด */}
          <Overview />
          {/* -------------------------------- */}

          <Box justify="flex-end" style={{ marginTop: 24 }}>
            <Search // component search
              addonBefore={
                <Select // ตัว dropdown search type
                  defaultValue="sname"
                  onChange={handleChangeSearchType}
                  style={{ minWidth: "150px" }}
                  options={searchOptions}
                />
              }
              style={{ width: "500px", backgroundColor: "var(--white)" }}
              placeholder="Search"
              allowClear
              onSearch={onChangeSearch}
            />
          </Box>
          <Box align="center" justify="flex-end">
            <Box gap={16} align="center">
              <Box gap={8} align="center">
                <Text type="normal bold" text={t("LABEL.form_major")} />
                <Select // dropdown filter major
                  defaultValue=""
                  onChange={onChangeSearchFormMajor}
                  style={{ minWidth: "350px" }}
                  options={formMajorOptions}
                />
              </Box>
              <Box gap={8} align="center">
                <Text type="normal bold" text={t("LABEL.form_type")} />
                <Select // dropdown filter pub type
                  defaultValue=""
                  onChange={onChangeSearchFormType}
                  style={{ minWidth: "150px" }}
                  options={formTypeOptions}
                />
              </Box>
              <Box gap={8} align="center">
                <Text type="normal bold" text={t("LABEL.status")} />
                <Select // dropdown filter status
                  defaultValue=""
                  style={{ minWidth: "150px" }}
                  onChange={onChangeSearchStatus}
                  options={statusOptions}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      );
    }
  }, [
    formTypeOptions,
    formMajorOptions,
    goToCreateForm,
    isStudent,
    onChangeSearch,
    query?.total,
    searchOptions,
    statusOptions,
    t,
  ]);

  const handleRefresh = () => {
    navigate({ pathname: `/` });
  };

  return (
    <Box direction="column" gap={24}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "16px",
        }}
      >
        <Text // หัวข้อ
          type="header"
          text={`${t("MENU.academic_publishing")}: ${t("MENU.form_status")}`}
          style={{ marginBottom: "16px" }}
        />
        <BaseButton
          type="primary"
          onClick={handleRefresh}
          icon={<ReloadOutlined />}
        >
          {t("LABEL.refresh")}
        </BaseButton>
      </div>
      {/* ---------------- ตัวเช็คการแสดงผล search/filter ระหว่างนักศึกษากับแอดมิน ---------------*/}
      {adminConfig}
      {/* -------------------------------------------------------------------- */}
      <Table // ตาราง request
        rowKey={"rid"}
        dataSource={requestList?.data}
        columns={columns(isStudent)}
        pagination={false}
        scroll={isStudent ? { x: 1500 } : { x: 1000 }}
        locale={{
          // เอาไว้เช็คเมื่อข้อมูลตารางไม่มี
          emptyText: isLoading ? (
            <Spin /> // ถ้าโหลดอยู่ให้ขึ้น spin
          ) : (
            <Empty // ถ้าไม่มีข้อมูลจะขึ้น empty
              description={
                <Text
                  type="normal gray"
                  text={
                    query?.search // เช็คถ้ามีการ search แล้วข้อมูลเป็น 0 จะขึ้น searchNotFound found แต่ถ้าไม่ขึ้น empty_form_list
                      ? t("LABEL.searchNotFound")
                      : t("LABEL.empty_form_list")
                  }
                />
              }
            />
          ),
        }}
      />
      <Box justify="center">
        <Pagination // component pagination
          current={query?.page} // page ปัจจุบันจะใช้ query.page
          total={query?.total} // total ทั้งหมดจะใช้ query.total
          pageSize={query.pageSize}
          pageSizeOptions={[10, 20, 30, 40, 50]}
          onChange={onChangePage}
          onShowSizeChange={onChangePageSize}
          showSizeChanger
        />
      </Box>
    </Box>
  );
});
