const apiDeleteRequest = async (rid) => { // api delete request
  const { default: axios } = await import("axios");
  try {
    const { data } = await axios.delete(
      `/api/verification/deletion?rid=${rid}`
    );
    return { data: data, error: null };
  } catch (error) {
    return { data: null, error: error };
  }
};

export default apiDeleteRequest;
