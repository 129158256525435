import { memo } from "react";
import { Col, Row, Form, Input, Select } from "antd";
import { Text } from "../../DesignSystem";
import { useDefaultFormLabel } from "../../hooks/useDefaultFormLabel";
import { useFieldValidate } from "../../hooks/useFieldValidate";
import { useSelectOption } from "../../hooks/useSelectOption";
import { t } from "i18next";

export const StudentInfo = memo(({ isViewOnly }) => {
  // render student form
  const { DEFAULT_STUDENT_INFO_FORM } = useDefaultFormLabel();
  const { requireField } = useFieldValidate();
  const { majorOptions, planOptions } = useSelectOption();

  return (
    <Row style={{ width: "100%" }}>
      <Row style={{ width: "100%" }} gutter={24}>
        {DEFAULT_STUDENT_INFO_FORM.map((item) => (
          <Col xs={24} sm={24} md={12} lg={8} xl={8} key={item?.key}>
            <Form.Item
              required={false}
              label={<Text type="normal bold" text={item?.label} />}
              name={item?.key}
              rules={
                item?.key === "stelno" ||
                item?.key === "seduplan" ||
                item?.key === "smajor"
                  ? [requireField]
                  : null
              }
            >
              {item?.type === "dropdown" ? (
                <Select
                  placeholder={t("LABEL.selectAnswer")}
                  options={item?.key === "smajor" ? majorOptions : planOptions}
                />
              ) : (
                <Input
                  placeholder={item?.label}
                  disabled={item?.disabled || isViewOnly}
                />
              )}
            </Form.Item>
          </Col>
        ))}
      </Row>
    </Row>
  );
});
