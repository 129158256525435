import { createContext, useContext } from "react";
import { useTranslation } from "react-i18next";

const LocalizationContext = createContext(null);

const defaultLng = "en";

function LocalizationProvider({ children }) { // global context ที่เอาไว้ใช้จัดการตัวแปลภาษา
    const [t, i18n] = useTranslation();

    const changeLanguage = (lng) => {
      i18n.changeLanguage(lng);
    };
    
    const getCurrentLng = () =>
      i18n?.language?.match(/[a-z]*/g)?.[0] ||
      window?.localStorage?.i18nextLng?.match(/[a-z]*/g)?.[0] ||
      defaultLng;

    const value = {
      changeLanguage,
      t,
      getCurrentLng,
    };
  
    return (
      <LocalizationContext.Provider value={value}>
        {children}
      </LocalizationContext.Provider>
    );
  }
  
  function useLocalizationContext() {
    const context = useContext(LocalizationContext);
    if (context === undefined) {
      throw new Error("Error context undefined");
    }
    return context;
  }
  
export { LocalizationProvider, useLocalizationContext }
