import { memo } from "react";
import { Radio, Form } from "antd";
import { Text } from "../../DesignSystem";
import { useLocalizationContext } from "../../../context/LocalizationContext";

export const SelectPublications = memo(() => { // render form เลือก pub type
  const { t } = useLocalizationContext();
  
  return (
    <Form.Item name="pubtype" style={{ marginBottom: 0 }}>
      <Radio.Group>
        <Radio value="CON">
          <Text type="normal bold" text={t("LABEL.conferences")} />
        </Radio>
        <Radio value="JOU">
          <Text type="normal bold" text={t("LABEL.journal")} />
        </Radio>
        <Radio value="OTH">
          <Text type="normal bold" text={t("LABEL.others")} />
        </Radio>
      </Radio.Group>
    </Form.Item>
  );
});
