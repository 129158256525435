import { Form } from "antd";
import { Box, Text } from "../../DesignSystem";
import { useLocalizationContext } from "../../../context/LocalizationContext";
import UploadFile from "../../Components/UploadFile";
import { useMemo } from "react";
import { useFieldValidate } from "../../hooks/useFieldValidate";

export const UploadForm = ({ form, publication, isViewOnly }) => {
  // render upload form ตาม pub type
  const { t } = useLocalizationContext();
  const { requireUpload } = useFieldValidate();
  const fileconcover = Form.useWatch("fileconcover", form);
  const filecontable = Form.useWatch("filecontable", form);
  const filejcover = Form.useWatch("filejcover", form);
  const filejtable = Form.useWatch("filejtable", form);
  const fileconcert = Form.useWatch("fileconcert", form);
  const filejaccept = Form.useWatch("filejaccept", form);
  const fileothdoc = Form.useWatch("fileothdoc", form);
  const fileconfullpaper = Form.useWatch("fileconfullpaper", form);
  const filejfullpaper = Form.useWatch("filejfullpaper", form);

  const displayUploadByPublication = useMemo(() => {
    switch (publication) {
      case "CON":
        return (
          <Box direction="column">
            <Form.Item // upload fileconcover
              name="fileconcover"
              required={false}
              label={
                <Box gap={4}>
                  <Text type="normal bold" text={`5.1 ` + t("LABEL.cover")} />
                  <a
                    href="/documents/conference/con_cover.pdf"
                    download="sample_con_cover.pdf"
                  >
                    <Text type="link" text={t("LABEL.example")} />
                  </a>
                </Box>
              }
              rules={[requireUpload]}
            >
              <UploadFile
                name="fileconcover"
                isViewOnly={isViewOnly}
                defaultFile={fileconcover}
              />
            </Form.Item>
            <Form.Item // upload filecontable
              name="filecontable"
              required={false}
              label={
                <Box gap={4}>
                  <Text type="normal bold" text={`5.2 ` + t("LABEL.index")} />
                  <a
                    href="/documents/conference/con_table.pdf"
                    download="sample_con_table.pdf"
                  >
                    <Text type="link" text={t("LABEL.example")} />
                  </a>
                </Box>
              }
              rules={[requireUpload]}
            >
              <UploadFile
                name="filecontable"
                isViewOnly={isViewOnly}
                defaultFile={filecontable}
              />
            </Form.Item>
            <Form.Item // upload con full
              name="fileconfullpaper"
              required={false}
              label={
                <Box gap={4}>
                  <Text
                    type="normal bold"
                    text={`5.3 ` + t("LABEL.con_full")}
                  />
                  <a
                    href="/documents/conference/con_full.pdf"
                    download="sample_con_full.pdf"
                  >
                    <Text type="link" text={t("LABEL.example")} />
                  </a>
                </Box>
              }
              rules={[requireUpload]}
            >
              <UploadFile
                name="fileconfullpaper"
                isViewOnly={isViewOnly}
                defaultFile={fileconfullpaper}
              />
            </Form.Item>
            <Form.Item // upload fileconcert
              name="fileconcert"
              required={false}
              label={
                <Box gap={5}>
                  <Text
                    type="normal bold"
                    text={`5.4 ` + t("LABEL.certificate_of_presentation")}
                  />
                  <a
                    href="/documents/conference/con_cert_1.pdf"
                    download="sample_con_cert_1.pdf"
                  >
                    <Text type="link" text={t("LABEL.example") + "(1), "} />
                  </a>
                  <a
                    href="/documents/conference/con_cert_2.pdf"
                    download="sample_con_cert_2.pdf"
                  >
                    <Text type="link" text={t("LABEL.example") + "(2)"} />
                  </a>
                </Box>
              }
              rules={[requireUpload]}
            >
              <UploadFile
                name="fileconcert"
                isViewOnly={isViewOnly}
                defaultFile={fileconcert}
              />
            </Form.Item>
            <div>
            <Text style={{ display: "inline" }} text={"*" + t("LABEL.certificate_of_presentation_note") + " "} />
              <a href="/documents/conference/rec_letter.pdf" download="recommendation_letter.pdf">
                <Text style={{ display: "inline" }} type="link" text="(Download)" /> 
              </a>
              </div>
          </Box>
        );
      case "JOU":
        return (
          <Box direction="column">
            <Form.Item // upload filejcover
              name="filejcover"
              required={false}
              label={
                <Box gap={4}>
                  <Text type="normal bold" text={`5.1 ` + t("LABEL.cover")} />
                </Box>
              }
              rules={[requireUpload]}
            >
              <UploadFile
                name="filejcover"
                isViewOnly={isViewOnly}
                defaultFile={filejcover}
              />
            </Form.Item>
            <Form.Item // upload filecontable
              name="filejtable"
              required={false}
              label={
                <Box gap={4}>
                  <Text type="normal bold" text={`5.2 ` + t("LABEL.index")} />
                </Box>
              }
              rules={[requireUpload]}
            >
              <UploadFile
                name="filejtable"
                isViewOnly={isViewOnly}
                defaultFile={filejtable}
              />
            </Form.Item>
            <Form.Item // upload con full
              name="filejfullpaper"
              required={false}
              label={
                <Box gap={4}>
                  <Text
                    type="normal bold"
                    text={`5.3 ` + t("LABEL.jou_full")}
                  />
                  <a
                    href="/documents/journal/j_full_1.pdf"
                    download="sample_jou_cert_1.pdf"
                  >
                    <Text type="link" text={t("LABEL.example") + "(1), "} />
                  </a>
                  <a
                    href="/documents/journal/j_full_2.pdf"
                    download="sample_jou_cert_2.pdf"
                  >
                    <Text type="link" text={t("LABEL.example") + "(2), "} />
                  </a>
                  <a
                    href="/documents/journal/j_full_3.pdf"
                    download="sample_jou_cert_3.pdf"
                  >
                    <Text type="link" text={t("LABEL.example") + "(3)"} />
                  </a>
                </Box>
              }
              rules={[requireUpload]}
            >
              <UploadFile
                name="filejfullpaper"
                isViewOnly={isViewOnly}
                defaultFile={filejfullpaper}
              />
            </Form.Item>
            <Form.Item // upload filejaccept
              name="filejaccept"
              required={false}
              label={
                <Box gap={4}>
                  <Text
                    type="normal bold"
                    text={`5.4 ` + t("LABEL.acceptance_letter")}
                  />
                  <a
                    href="/documents/journal/j_accept_1.pdf"
                    download="sample_jou_accept_1.pdf"
                  >
                    <Text type="link" text={t("LABEL.example") + "(1), "} />
                  </a>
                  <a
                    href="/documents/journal/j_accept_2.pdf"
                    download="sample_jou_accept_2.pdf"
                  >
                    <Text type="link" text={t("LABEL.example") + "(2), "} />
                  </a>
                  <a
                    href="/documents/journal/j_accept_3.pdf"
                    download="sample_jou_accept_3.pdf"
                  >
                    <Text type="link" text={t("LABEL.example") + "(3), "} />
                  </a>
                  <a
                    href="/documents/journal/j_cert.pdf"
                    download="sample_jou_cert.pdf"
                  >
                    <Text type="link" text={t("LABEL.example") + "(4)"} />
                  </a>
                </Box>
              }
              rules={[requireUpload]}
            >
              <UploadFile
                name="filejaccept"
                isViewOnly={isViewOnly}
                defaultFile={filejaccept}
              />
            </Form.Item>
          </Box>
        );
      default:
        return (
          // upload fileothdoc
          <Form.Item name="fileothdoc" required={false} rules={[requireUpload]}>
            <UploadFile
              name="fileothdoc"
              isViewOnly={isViewOnly}
              defaultFile={fileothdoc}
            />
          </Form.Item>
        );
    }
  }, [
    publication,
    t,
    requireUpload,
    isViewOnly,
    fileconcover,
    filecontable,
    filejcover,
    filejtable,
    fileconcert,
    filejaccept,
    fileconfullpaper,
    filejfullpaper,
    fileothdoc,
  ]);

  return displayUploadByPublication;
};
