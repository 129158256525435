import "./App.css";
import { LocalizationProvider } from "./context/LocalizationContext";
import { UserDataProvider } from "./context/UserDataContext";
import LayoutPage from "./components/LayoutPage";
import GlobalStyles from "./GlobalStyle";
import { BrowserRouter as Router } from "react-router-dom";
import { SWRConfig } from "swr";
import { fetcher } from "./service/use-swr";
import { NotificationProvider } from "./context/NotificationContext";

function App() {
  return (
    <SWRConfig
      value={{
        revalidateOnFocus: false,
        errorRetryCount: 2,
        fetcher,
      }}
    >
      <Router>
        <UserDataProvider>
          <LocalizationProvider>
            <NotificationProvider>
              <LayoutPage />
            </NotificationProvider>
            <GlobalStyles />
          </LocalizationProvider>
        </UserDataProvider>
      </Router>
    </SWRConfig>
  );
}

export default App;
