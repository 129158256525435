import { memo } from "react";
import { useDefaultFormLabel } from "../../hooks/useDefaultFormLabel";
import { Col, DatePicker, Form, Input, InputNumber, Radio, Row } from "antd";
import { Text } from "../../DesignSystem";
import { useLocalizationContext } from "../../../context/LocalizationContext";
import { useFieldValidate } from "../../hooks/useFieldValidate";

export const JournalQuestionForm = memo(({ form }) => {
  // render journal ส่วนที่ต้องกรอกเพิ่มเติม
  const { t } = useLocalizationContext();
  const { DEFAULT_JOURNAL_QUESTION_FORM } = useDefaultFormLabel();
  const { requireField, requireSelect } = useFieldValidate();

  const isOtherTci = Form.useWatch("jtci", form) === "OTH"; // เช็ค jtci เป็น oth รึป่าว
  const isNAT = Form.useWatch("jtype", form) === "NAT"; // เช็ค jtype เป็น nat รึป่าว
  // const isOtherArticle = Form.useWatch("jatype", form) === "OTH"; // เช็ค jatype เป็น oth รึป่าว
  const isOtherDatabase = Form.useWatch("jdb", form) === "OTH"; // เช็ค jdb เป็น oth รึป่าว

  return (
    <Row gutter={24} style={{ width: "100%" }}>
      {DEFAULT_JOURNAL_QUESTION_FORM.map((item, index) => (
        <Col key={item?.key} xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name={item?.key}
            label={<Text type="normal bold" text={`3.` + (index+ 6) + " " + item?.label} />}
            required={false}
            rules={[requireField]}
          >
            {item?.type === "input" ? ( // ถ้า type เป็น input จะแสดง input field
              <Input placeholder={item?.label} />
            ) : item?.type === "number" ? ( // ถ้า type เป็น number จะแสดง input number field
              <InputNumber min={1} style={{ width: "100%" }} placeholder="0" />
            ) : item?.type === "inputDecimal" ? ( // ถ้า type เป็น inputDecimal จะแสดง input number decimal field
              <InputNumber
                style={{ width: "100%" }}
                min="0"
                step="0.001"
                placeholder="0.000"
              />
            ) : (
              // ถ้าไม่เข้าเคสจะแสดง select date field
              <DatePicker
                style={{ width: "100%" }}
                placeholder={t("LABEL.date_placeholder")}
                format="DD/MM/YYYY"
              />
            )}
          </Form.Item>
        </Col>
      ))}

      {/* ---------- form เลือก jatype --------- */}
      {/* <Col span={24}>
        <Form.Item
          name="jatype"
          label={
            <Text type="normal bold" text={t("LABEL.types_of_articles")} />
          }
          required={false}
          rules={[requireSelect]}
        >
          <Radio.Group>
            <Radio value="RES">
              <Text type="normal bold" text={t("LABEL.research_article")} />
            </Radio>
            <Radio value="ACA">
              <Text type="normal bold" text={t("LABEL.academic_article")} />
            </Radio>
            <Radio value="REV">
              <Text type="normal bold" text={t("LABEL.review_article")} />
            </Radio>
            <Radio value="CSR">
              <Text type="normal bold" text={t("LABEL.case_report")} />
            </Radio>
            <Radio value="OTH">
              <Text type="normal bold" text={t("LABEL.other")} />
            </Radio>
          </Radio.Group>
        </Form.Item>
        {isOtherArticle && (
          <Form.Item name="jatypeother" required={false} rules={[requireField]}>
            <Input placeholder={t("LABEL.other")} />
          </Form.Item>
        )}
      </Col> */}
      {/* -------------------------------- */}

      {/* ---------------- form เลือก jdb ---------------- */}
      <Col span={24}>
        <Form.Item
          name="jdb"
          label={<Text type="normal bold" text={`3.12 `+ t("LABEL.database")} />}
          required={false}
          rules={[requireSelect]}
        >
          <Radio.Group>
            <Radio value="SCOPUS">
              <Text type="normal bold" text="SCOPUS" />
            </Radio>
            <Radio value="ISI">
              <Text type="normal bold" text="ISI" />
            </Radio>
            <Radio value="PUBMED">
              <Text type="normal bold" text="PubMed" />
            </Radio>
            <Radio value="NOT">
              <Text
                type="normal bold"
                text={t("LABEL.not_referenced_in_database")}
              />
            </Radio>
            <Radio value="OTH">
              <Text type="normal bold" text={t("LABEL.other")} />
            </Radio>
          </Radio.Group>
        </Form.Item>
        {isOtherDatabase && (
          <Form.Item name="jdbother" required={false} rules={[requireField]}>
            <Input placeholder={t("LABEL.other")} />
          </Form.Item>
        )}
      </Col>
      {/* -------------------------------------------- */}

      {/* ------------------ form เลือก jquartile ---------------------- */}
      {/* <Col span={24}>
        <Form.Item
          name="jquartile"
          label={<Text type="normal bold" text={t("LABEL.journalQuartile")} />}
          required={false}
          rules={[requireSelect]}
        >
          <Radio.Group>
            <Radio value={1}>
              <Text type="normal bold" text="1" />
            </Radio>
            <Radio value={2}>
              <Text type="normal bold" text="2" />
            </Radio>
            <Radio value={3}>
              <Text type="normal bold" text="3" />
            </Radio>
            <Radio value={4}>
              <Text type="normal bold" text="4" />
            </Radio>
          </Radio.Group>
        </Form.Item>
      </Col> */}


      {/* ------------------- form เลือก jispredatory ------------------- */}
      <Col span={24}>
        <Form.Item
          name="jispredatory"
          label={<Text type="normal bold" text={`3.13 `+ t("LABEL.predatoryJournal")} />}
          required={false}
          rules={[requireSelect]}
        >
          <Radio.Group>
            <Radio value="true">
              <Text type="normal bold" text="Yes" />
            </Radio>
            <Radio value="false">
              <Text type="normal bold" text="No" />
            </Radio>
          </Radio.Group>
        </Form.Item>
      </Col>
      {/* ----------------------------------------------- */}

      {/* --------- form เลือก jtci -------- */}
      <Col span={24}>
        {isNAT && (
          <Form.Item
            name="jtci"
            label={<Text type="normal bold" text={`3.14 `+ t("LABEL.tci_tier_title")} />}
            required={false}
            rules={[requireSelect]}
          >
            <Radio.Group>
              <Radio value="T1">
                <Text
                  type="normal bold"
                  text={t("LABEL.tci_tier", { value: 1 })}
                />
              </Radio>
              <Radio value="T2">
                <Text
                  type="normal bold"
                  text={t("LABEL.tci_tier", { value: 2 })}
                />
              </Radio>
              <Radio value="T3">
                <Text
                  type="normal bold"
                  text={t("LABEL.tci_tier", { value: 3 })}
                />
              </Radio>
              <Radio value="NOT">
                <Text
                  type="normal bold"
                  text={t("LABEL.not_referenced_in_database")}
                />
              </Radio>
              <Radio value="OTH">
                <Text type="normal bold" text={t("LABEL.other")} />
              </Radio>
            </Radio.Group>
          </Form.Item>
        )}
        {isNAT && isOtherTci && (
          <Form.Item name="jtciother" required={false} rules={[requireField]}>
            <Input placeholder={t("LABEL.other")} />
          </Form.Item>
        )}
      </Col>
      {/* ---------------------------------------------------- */}
    </Row>
  );
});
