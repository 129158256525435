import useSWR from "swr";
export const requestListApi = `/api/verification/requests?page=1&sid=&size=5&smajor=&pubtype=&rstatus=&searchtype=&key=`;
export const useGetRequestList = ({
  // api get request list
  page,
  pageSize,
  pubtype = "",
  smajor = "",
  rstatus = "",
  searchtype,
  search,
}) => {
  const userData = localStorage.getItem("user");
  const isAdmin = userData?.admin;

  let sid = ""; // หน่วงเวลา หรือรอโหลดเสร็จ
  if (userData) {
    sid = JSON.parse(userData)?.student_id
      ? JSON.parse(userData)?.student_id
      : "";
  }
  const { data, error } = useSWR(
    `/api/verification/requests?page=${page}${
      isAdmin ? "" : `&sid=${sid}`
    }&size=${pageSize}&smajor=${smajor}&pubtype=${pubtype}&rstatus=${rstatus}` +
      `&searchtype=${searchtype}&key=${search}`,
    {
      shouldRetryOnError: false,
    }
  );

  return {
    data,
    isLoading: !data && !error,
    error,
  };
};
