import { Route, Routes } from "react-router-dom";
import FormStatus from "./academicPublishing/FormStatus";
import CreateForm from "./academicPublishing/CreateForm";

const PageRoutes = () => {
  // จัดการ path route ต่างๆ
  return (
    <Routes>
      <Route exact path="/form-status" element={<FormStatus />} />
      <Route path="/create-form" element={<CreateForm />} />
    </Routes>
  );
};

export default PageRoutes;
