import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { BaseButton, Box, Text } from "../DesignSystem";
import { Card, Divider, Form, Input, Radio, Skeleton } from "antd";
import { useLocalizationContext } from "../../context/LocalizationContext";
import { StudentInfo } from "./Forms/StudentInfo";
import {
  // UndoOutlined,
  SaveOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import { SelectPublications } from "./Forms/SelectPublications";
import { ConferencesForm } from "./Forms/ConferencesForm";
import { JournalForm } from "./Forms/JournalForm";
import { OtherForm } from "./Forms/OtherForm";
import { QuestionForm } from "./Forms/QuestionForm";
import { JournalQuestionForm } from "./Forms/JournalQuestionForm";
import { UploadForm } from "./Forms/UploadForm";
import { useSearchParams } from "react-router-dom";
import { useGetRequestById } from "../api/useGetRequestById";
import { useGetFormPayload } from "../hooks/useGetFormPayload";
import apiCreateForm from "../api/apiCreateForm";
import { useGoToRoute } from "../hooks/useGoToRoute";
import { useNotification } from "../hooks/useNotification";
import { useCreateForm } from "../hooks/useCreateForm";
import { useConFirmModal } from "../hooks/useConFirmModal";
import AdminActionCard from "../Components/AdminActionCard";
import { useUserDataContext } from "../../context/UserDataContext";

export default memo(function CreateForm() {
  // หน้าที่เอาไว้แสดง create request
  const { t, getCurrentLng } = useLocalizationContext();
  const [form] = Form.useForm();
  const [adminForm] = Form.useForm();
  const { getPayload } = useGetFormPayload();
  const { goToFormStatus } = useGoToRoute();
  const [latestComment, setLatestComment] = useState("");
  const pubtype = Form.useWatch("pubtype", form);
  const contype = Form.useWatch("contype", form);
  const jtype = Form.useWatch("jtype", form);
  const rstatus = Form.useWatch("rstatus", adminForm);
  const { userData, isStudent } = useUserDataContext();

  const {
    notiCreateSuccess,
    notiCreateFailed,
    notiSystemError,
    notiUpdateSuccess,
  } = useNotification();
  const { getDefaultValue, handleSetDefaultFromApi } = useCreateForm(); // เรียก noti
  const {
    // showConfirmReset,
    showConfirmSubmit,
    showConfirmApprove,
    showConfirmReject,
  } = useConFirmModal(); // เรียก modal confirm

  const publicationRef = useRef(pubtype);

  const [searchParams] = useSearchParams();
  const requestId = searchParams.get("request"); // get request id จาก pathName

  const { data: requestData, isLoading, error } = useGetRequestById(requestId); // เรียก api get request by id

  useEffect(() => {
    if (isStudent && userData) {
      form.setFieldsValue({
        sid: userData?.student_id,
        sname: `${
          userData?.first_name?.[getCurrentLng() === "en" ? "en_US" : "th_TH"]
        } ${
          userData?.last_name?.[getCurrentLng() === "en" ? "en_US" : "th_TH"]
        }`,
        semail: `${userData?.itaccount_name}@cmu.ac.th`,
      });
    }
  }, [form, getCurrentLng, isStudent, userData]);

  const isViewOnly = useMemo(() => {
    // เช็คว่าเข้ามาเพื่อ view อย่างเดียวใช่หรือไม่
    if (requestData) {
      if (isStudent) {
        return requestData?.rstatus === "APPROVED";
      } else {
        return true;
      }
    } else {
      return false;
    }
  }, [isStudent, requestData]);

  useEffect(() => {
    // ถ้ามี error จาก api get request by id ให้ขึ้น noti system error
    if (error && !isLoading) {
      notiSystemError();
    }
  }, [error, t, isLoading, notiSystemError]);

  useEffect(() => {
    if (requestId && requestData) {
      // ถ้ามี requestId และมีข้อมูลจากหลังบ้านส่งมา (ก็คือเป็นโหมด view/edit)
      form.setFieldsValue({
        // set default value ให้ pub type และ con type
        pubtype: requestData?.pubtype,
        contype: requestData?.contype,
        jtype: requestData?.jtype,
      });
      setTimeout(() => {
        // set default value ให้ทุกๆ field
        form.setFieldsValue(handleSetDefaultFromApi(requestData));
        if (requestData?.foreigns && requestData?.foreigns?.length !== 0) {
          requestData?.foreigns?.forEach((item, index) => {
            form.setFieldValue(["foreigns", index, "fname"], item?.fname);
            form.setFieldValue(["foreigns", index, "funi"], item?.funi);
            form.setFieldValue(["foreigns", index, "fcountry"], item?.fcountry);
          });
        }
      }, 500);
      if (!isStudent) {
        // ถ้าเป็นแอดมินให้ set default value ให้ rcomment โดยใช้ state latestComment
        setLatestComment(requestData?.rcomment || "");
      }
    }
  }, [form, handleSetDefaultFromApi, requestData, requestId, isStudent]);

  const handleReset = useCallback(() => {
    // func จัดการการ reset ข้อมูลเมื่อมีการกด reset
    const allFields = Object.keys(form.getFieldsValue());
    const filterRequire = allFields?.filter(
      (item) =>
        item !== "pubtype" &&
        item !== "contype" &&
        item !== "stelno" &&
        item !== "sname" &&
        item !== "sid" &&
        item !== "semail" &&
        item !== "smajor" &&
        item !== "seduplan"
    );
    form.resetFields(filterRequire); // reset ข้อมูลทุกอันยกเว้น pubtype contype stelno
  }, [form]);

  useEffect(() => {
    if (pubtype !== publicationRef.current) {
      handleReset();
    }

    publicationRef.current = pubtype;
  }, [handleReset, pubtype]);

  const formPublicationTitle = useMemo(() => {
    switch (pubtype) {
      case "CON":
        return t("LABEL.conferences");
      case "JOU":
        return t("LABEL.journal");
      case "OTH":
        return t("LABEL.others");
      default:
        break;
    }
  }, [pubtype, t]);

  const formPublication = useMemo(() => {
    // เปลี่ยน form กรอกข้อมูลตาม pubtype ที่เลือก
    switch (pubtype) {
      case "CON":
        return <ConferencesForm contype={contype} />;
      case "JOU":
        return <JournalForm jtype={jtype} />;
      case "OTH":
        return <OtherForm />;
      default:
        break;
    }
  }, [pubtype, contype, jtype]);

  const onSubmitForm = async (name, info) => {
    // จัดการข้อมูลเมื่อมีการกด submit
    const payloadCreate = getPayload(info?.values); // payload สำหรับตอน create
    const pubFormValues = getPayload(form.getFieldsValue()); // payload สำหรับ edit
    const payloadEdit = {
      // ประกอบ payload สำหรับ edit และ แอดมินเปลี่ยนสถานะเข้าด้วยกัน
      ...pubFormValues,
      rid: requestId,
      rstatus: isStudent ? "PENDING" : rstatus,
      rcomment:
        rstatus === "APPROVED"
          ? ""
          : info?.values?.rcomment || requestData?.rcomment,
    };
    if (payloadCreate || payloadEdit) {
      const { data, error } = await apiCreateForm(
        requestId ? payloadEdit : { ...payloadCreate, rstatus: "PENDING" }
      );
      if (data) {
        requestId ? notiUpdateSuccess() : notiCreateSuccess();
        goToFormStatus();
      }
      if (error) {
        notiSystemError();
      }
    }
  };

  const title = useMemo(() => {
    if (requestId) {
      switch (requestData?.pubtype) {
        case "CON":
          return `${t("MENU.academic_publishing")}: ${t("LABEL.conferences")}`;
        case "JOU":
          return `${t("MENU.academic_publishing")}: ${t("LABEL.journal")}`;
        case "OTH":
          return `${t("MENU.academic_publishing")}: ${t("LABEL.others")}`;
        default:
          break;
      }
    } else {
      return `${t("MENU.academic_publishing")}: ${t("MENU.create_form")}`;
    }
  }, [requestData?.pubtype, requestId, t]);

  return (
    <Form.Provider
      onFormFinish={(name, info) => {
        if (name === "studentForm") {
          // ถ้าเป็นโหมด student ให้ขึ้น modal confirm submit
          showConfirmSubmit({ onOk: () => onSubmitForm(name, info) });
        } else {
          // ถ้าไม่ใช่โหมด student
          if (rstatus === "APPROVED") {
            // ถ้าแอดมินเลือก approve ให้ขึ้น modal confirm approve
            showConfirmApprove({ onOk: () => onSubmitForm(name, info) });
          } else {
            // ถ้าเลือก reject ขึ้น modal confirm reject
            showConfirmReject({ onOk: () => onSubmitForm(name, info) });
          }
        }
      }}
    >
      <Form
        form={form}
        name="studentForm"
        layout="vertical"
        onFinishFailed={notiCreateFailed} // ถ้ากด submit แล้วติด validate จะขึ้น notiCreateFailed
        initialValues={getDefaultValue} // initial value ของ field
        disabled={isViewOnly} // ถ้าเป็น view only ให้ disabled ทุกช่อง
      >
        {requestId && isLoading ? ( // เช็คการขึ้น loading
          <Card bodyStyle={{ padding: "16px" }}>
            <Box direction="column">
              <Skeleton active />
              <Skeleton active />
              <Skeleton active />
            </Box>
          </Card>
        ) : (
          <Box direction="column" gap={24}>
            {/* ------------- ส่วน header บอก title ของหน้า + ลูกศร back เพื่อกลับไปหน้าแรก ------------ */}
            <Card bodyStyle={{ padding: "16px" }}>
              <Box align="center" gap={16}>
                <ArrowLeftOutlined onClick={goToFormStatus} />
                <Text type="header" text={title} />
              </Box>
            </Card>
            {/* ---------------------------------------------------------------------------------- */}

            {/* ---------------------------- form ของข้อมูลนักศึกษา ---------------------------- */}
            <Card
              headStyle={{ padding: "16px" }}
              bodyStyle={{ padding: "16px" }}
              title={
                <Text
                  type="card title"
                  text={`1. ` + t("LABEL.student_info")}
                />
              }
            >
              <StudentInfo isViewOnly={isViewOnly} />
            </Card>
            {/* ----------------------------------------------------------------------------- */}

            {/* ---------------------------- form สำหรับเลือก pub type ------------------------ */}
            <Card
              headStyle={{ padding: "16px" }}
              bodyStyle={{ padding: "16px" }}
              title={
                <Text
                  type="card title"
                  text={`2. ` + t("LABEL.select_the_publications")}
                />
              }
            >
              <SelectPublications form={form} />
            </Card>
            {/* ----------------------------------------------------------------------------- */}

            {/* --------------------- แสดง form ตาม pub type ที่เลือก ------------------------- */}
            <Card
              headStyle={{
                padding: "16px",
                // เช็คสีตาม pub type
                backgroundColor:
                  pubtype === "CON"
                    ? "var(--orange-secondary)"
                    : pubtype === "JOU"
                    ? "var(--red-secondary)"
                    : "var(--blue-secondary)",
              }}
              bodyStyle={{ padding: "16px" }}
              title={
                <Text type="card title" text={`3. ` + formPublicationTitle} />
              }
            >
              {/* ------------ ถ้าเลือก conferences หรือ journal จะมี nat กับ inat ให้เลือก ------------ */}
              {(pubtype === "CON" || pubtype === "JOU") && (
                <Form.Item
                  name={pubtype === "JOU" ? "jtype" : "contype"}
                  label={
                    <Text
                      type="normal bold"
                      text={
                        pubtype === "CON"
                          ? `3.1. ` + t("LABEL.type_of_conferences")
                          : `3.1. ` + t("LABEL.type_of_journal")
                      }
                    />
                  }
                >
                  <Radio.Group>
                    <Radio value="NAT">
                      <Text
                        type="normal bold"
                        text={t("LABEL.national_conferences")}
                      />
                    </Radio>
                    <Radio value="INAT">
                      <Text
                        type="normal bold"
                        text={t("LABEL.international_conferences")}
                      />
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              )}
              {/* -------------------------------------------------------- */}

              {/* --------- แสดง form ตาม pub type ที่เลือก -------------- */}
              {formPublication}
              {/* ------------------------------------------------------ */}

              {/* --------- ถ้าเลือก journal จะมี form เพิ่มเติมมาให้กรอก ------------- */}
              {pubtype === "JOU" && (
                <>
                  <JournalQuestionForm form={form} />
                  <Divider />
                </>
              )}
              {/* -------------------------------------------------------------- */}
            </Card>

            {/* --------------------------------------------------------------------------- */}

            <Card
              headStyle={{ padding: "16px" }}
              bodyStyle={{ padding: "16px" }}
              title={
                <Text
                  type="card title"
                  text={`4. ` + t("LABEL.additional_info")}
                />
              }
            >
              {/* --------------- input line url ขึ้นทั้ง 3 type ------------------ */}
              <Form.Item
                name={
                  pubtype === "CON"
                    ? "conlink"
                    : pubtype === "JOU"
                    ? "jlink"
                    : pubtype === "OTH"
                    ? "othlink"
                    : "othlink"
                }
                label={
                  <Text
                    type="normal bold"
                    text={`4.1 ` + t("LABEL.link_or_source_for_disseminating")}
                  />
                }
                required={false}
              >
                <Input
                  style={{ width: "50%" }}
                  placeholder="Link"
                  disabled={isViewOnly}
                />
              </Form.Item>
              {/* --------- ถ้าเลือก conferences หรือ journal จะมีส่วนของคำถามเสริมให้กรอก ---------- */}
              {(pubtype === "CON" || pubtype === "JOU") && (
                <>
                  <QuestionForm isJournal={pubtype === "JOU"} form={form} />
                </>
              )}
              {/* --------------------------------------------------------------------------- */}
            </Card>
            <Card
              headStyle={{ padding: "16px" }}
              bodyStyle={{ padding: "16px" }}
              title={
                <Text
                  type="card title"
                  text={`5. ` + t("LABEL.upload_files")}
                />
              }
            >
              {/* ---------------------- component upload file --------------------- */}
              <UploadForm
                publication={pubtype}
                isViewOnly={isViewOnly}
                form={form}
              />
              {/* ------------------------------------------------------------------- */}
            </Card>
            {/* ---------------------------------------------------------------------- */}

            {isStudent &&
              !isViewOnly && ( // ถ้าเป็นโหมด student และเป็น create/edit จะแสดงปุ่ม reset และ submit
                <Card bodyStyle={{ padding: "16px" }}>
                  <Box gap={16}>
                    {/* <BaseButton // ปุ่ม reset
                      type="primary"
                      size="large"
                      danger
                      style={{ width: "100%" }}
                      icon={<UndoOutlined />}
                      onClick={() =>
                        showConfirmReset({ onOk: () => form.resetFields() })
                      }
                    >
                      {t("LABEL.reset")}
                    </BaseButton> */}
                    <BaseButton // ปุ่ม submit
                      type="primary"
                      size="large"
                      style={{ width: "100%" }}
                      icon={<SaveOutlined />}
                      onClick={() => form.submit()}
                    >
                      {t("LABEL.submit")}
                    </BaseButton>
                  </Box>
                </Card>
              )}
          </Box>
        )}
      </Form>
      {/* ------ ถ้าเป็น admin และสถานะไม่ใช่ approve แล้วจะแสดงส่วนที่ให้เลือก reject approve ------- */}
      {!isStudent && requestData?.rstatus !== "APPROVED" && (
        <Form name="adminAction" form={adminForm} style={{ marginTop: "24px" }}>
          <AdminActionCard
            rstatus={rstatus}
            onSubmit={() => adminForm.submit()}
            latestComment={latestComment}
          />
        </Form>
      )}
      {/* ---------------------------------------------------------------------------------- */}
    </Form.Provider>
  );
});
