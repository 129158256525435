const apiCreateForm = async (payload) => { // api create request
  const { default: axios } = await import("axios");
  try {
    const { data } = await axios.post(
      `/api/verification/submission`,
      payload
    );
    return { data: data, error: null };
  } catch (error) {
    return { data: null, error: error };
  }
};

export default apiCreateForm;
