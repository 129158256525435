import { notification } from "antd";
import { createContext, useContext } from "react";

const NotificationContext = createContext(null);

function NotificationProvider({ children }) {
  const [api, contextHolder] = notification.useNotification();

  const value = {
    notification: api,
    contextHolder,
  };

  return (
    <NotificationContext.Provider value={value}>
      {children}
    </NotificationContext.Provider>
  );
}

function useNotificationContext() {
  const context = useContext(NotificationContext);
  if (context === undefined) {
    throw new Error("Error context undefined");
  }
  return context;
}

export { NotificationProvider, useNotificationContext };
