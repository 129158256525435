import React from "react";
import { useLocalizationContext } from "../../context/LocalizationContext";
import { ReadOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";

export const useMenuSider = () => { // ใช้สำหรับเก็บข้อมูล side menu ถ้าจะเพิ่มเมนูใน side bar ให้เพิ่มในนี้
  const { t } = useLocalizationContext();

  const DEFAULT_MENU = [
    {
      key: "academicPublishing",
      icon: React.createElement(ReadOutlined),
      label: ( // label คือเมนูใหญ่
        <Tooltip title={t("MENU.academic_publishing")} placement="right">
          {t("MENU.academic_publishing")}
        </Tooltip>
      ),
      children: [ // children คือ sub menu
        {
          key: "form-status",
          label: (
            <Tooltip title={t("MENU.form_status")} placement="right">
              {t("MENU.form_status")}
            </Tooltip>
          ),
        },
      ],
    },
  ];

  return { DEFAULT_MENU };
};
