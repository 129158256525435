import useSWR from "swr";

export const useGetRequestById = (requestId) => { // api get request by id
  const { data, error } = useSWR(
    requestId && `/api/verification/request?rid=${requestId}`,
    {
      shouldRetryOnError: false,
    }
  );

  return {
    data,
    isLoading: !data && !error,
    error,
  };
};
