import { useCallback } from "react";
import { useLocalizationContext } from "../../context/LocalizationContext";
import { useNotificationContext } from "../../context/NotificationContext";

export const useNotification = () => { // ใช้สำหรับรวม notification
  const { notification } = useNotificationContext();
  const { t } = useLocalizationContext();

  const notiCreateSuccess = () => { // noti create success ขึ้นเมื่อสร้างเอกสารสำเร็จ
    notification.success({
      message: t("LABEL.create_success"),
    });
  };

  const notiUpdateSuccess = () => { // noti update complete ขึ้นเมื่อแก้ไขเอกสารสำเร็จ
    notification.success({
      message: t("LABEL.update_complete"),
    });
  };

  const notiSystemError = useCallback(() => { // noti system error ขึ้นเมื่อเกิด error จาก api
    notification.error({
      message: t("ERROR.system_error"),
    });
  }, [notification, t]);

  const notiCreateFailed = useCallback(() => { // noti create failed ขึ้นเมื่อกด submit แล้วติด validate
    notification.error({
      message: t("VALIDATE.submit_failed"),
    });
  }, [notification, t]);

  const notiUploadMaximumSize = useCallback(() => { // noti maximum file size ขึ้นเมื่อ upload file แล้ว size เกิน
    notification.error({
      message: t("VALIDATE.upload_maximum_size"),
    });
  }, [notification, t]);

  const notiUploadWrongType = useCallback(() => { // noti wrong file type ขึ้นเมื่อ upload file แล้ว type ไม่ใช่ pdf
    notification.error({
      message: t("VALIDATE.upload_wrong_type"),
    });
  }, [notification, t]);

  return {
    notiCreateSuccess,
    notiCreateFailed,
    notiSystemError,
    notiUploadMaximumSize,
    notiUploadWrongType,
    notiUpdateSuccess
  };
};
