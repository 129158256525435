import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

export const useGetFormPayload = () => {
  // ใช้สำหรับประกอบข้อมูลเป้น payload ส่งไปหลังบ้าน
  const getPayload = (data) => {
    const payload = {
      // payload ข้อมูลที่ต้องส่งไปทั้ง 3 type
      sid: data?.sid || "-",
      sname: data?.sname || "-",
      smajor: data?.smajor || "-",
      seduplan: data?.seduplan || "-",
      semail: data?.semail || "-",
      stelno: data?.stelno || "-",
      pubtype: data?.pubtype,
      contype: data?.contype,
      jtype: data?.jtype,
      rcomment: data?.rcomment || "",
    };
    if (data?.pubtype === "CON") {
      // payload สำหรับ conferences
      return {
        ...payload,
        ttitle: data?.ttitle || "",
        etitle: data?.etitle || "",
        author: data?.author || "",
        aname: data?.aname || "",
        anameco_1: data?.anameco_1 || "",
        anameco_2: data?.anameco_2 || "",
        conname: data?.conname || "",
        condestination: data?.condestination || "",
        conbegindate: data?.condate?.[0] || "",
        conenddate: data?.condate?.[1] || "",
        isfirst: data?.isfirst === "true" ? 1 : 0,
        hasadv: data?.hasadv === "true" ? 1 : 0,
        hasforeign: data?.hasforeign === "true" ? 1 : 0,
        foreigns: data?.foreigns
          ? data?.foreigns?.filter((item) => item?.fname !== undefined)
          : [],
        fileconcover: data?.fileconcover?.fileconcover || data?.fileconcover,
        filecontable: data?.filecontable?.filecontable || data?.filecontable,
        fileconcert: data?.fileconcert?.fileconcert || data?.fileconcert,
        fileconfullpaper:
          data?.fileconfullpaper?.fileconfullpaper || data?.fileconfullpaper,
        conlink: data?.conlink,
      };
    }
    if (data?.pubtype === "JOU") {
      // payload สำหรับ journal
      return {
        ...payload,
        ttitle: data?.ttitle || "",
        etitle: data?.etitle || "",
        author: data?.author || "",
        aname: data?.aname || "",
        anameco_1: data?.anameco_1 || "",
        anameco_2: data?.anameco_2 || "",
        isfirst: data?.isfirst === "true" ? 1 : 0,
        hasadv: data?.hasadv === "true" ? 1 : 0,
        hasforeign: data?.hasforeign === "true" ? 1 : 0,
        foreigns: data?.foreigns
          ? data?.foreigns?.filter((item) => item?.fname !== undefined)
          : [],
        jpage: data?.jpage || "",
        jsubmitdate: data?.jsubmitdate
          ? dayjs(data?.jsubmitdate).utc().format("YYYY-MM-DDTHH:mm:ss[Z]")
          : "",
        isjaccepted: data?.isjaccepted === "true" ? 1 : 0,
        jaccepteddate: data?.jaccepteddate
          ? dayjs(data?.jaccepteddate).utc().format("YYYY-MM-DDTHH:mm:ss[Z]")
          : "",
        jname: data?.jname || "",
        jno: data?.jno || "",
        jvol: data?.jvol?.toString() || "",
        jyear: data?.jyear?.toString() || "",
        // jmonthyear: data?.jmonthyear
        //   ? dayjs(data?.jmonthyear).utc().format("YYYY-MM-DDTHH:mm:ss[Z]")
        //   : "",
        // jimpfscore: data?.jimpfscore?.toString() || "",
        // jimpfdate: data?.jimpfdate
        //   ? dayjs(data?.jimpfdate).utc().format("YYYY-MM-DDTHH:mm:ss[Z]")
        //   : "",
        jtci: data?.jtci || "",
        jtciother: data?.jtciother || "",
        // jatype: data?.jatype || "",
        // jatypeother: data?.jatypeother || "",
        jdb: data?.jdb || "",
        jdbother: data?.jdbother || "",
        jquartile: data?.jquartile || 0,
        jispredatory: data?.jispredatory === "true" ? 1 : 0,
        jlink: data?.jlink || "",
        filejcover: data?.filejcover?.filejcover || data?.filejcover || "",
        filejtable: data?.filejtable?.filejtable || data?.filejtable || "",
        filejaccept: data?.filejaccept?.filejaccept || data?.filejaccept || "",
        filejfullpaper:
          data?.filejfullpaper?.filejfullpaper ||
          data?.filejfullpaper ||
          "",
      };
    }
    if (data?.pubtype === "OTH") {
      // payload สำหรับ other
      return {
        ...payload,
        ttitle: "",
        etitle: "",
        author: "",
        aname: "",
        isfirst: 0,
        hasadv: 0,
        hasforeign: 0,
        othdocname: data?.othdocname || "",
        othlink: data?.othlink || "",
        fileothdoc: data?.fileothdoc?.fileothdoc || data?.fileothdoc || "",
      };
    }
  };
  return { getPayload };
};
