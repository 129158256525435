const apiGetFileUrl = async (file, filename) => { // api get file url
  const { default: axios } = await import("axios");

  const formData = new FormData();
  formData.append("file", file);
  formData.append("filename", filename);
  try {
    const { data } = await axios.post(`/api/verification/fileurl`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    return { data: data, error: null };
  } catch (error) {
    return { data: null, error: error };
  }
};

export default apiGetFileUrl;
