import { Card, Col, Row } from "antd";
import { memo, useEffect } from "react";
import { Box, Text } from "../DesignSystem";
import { useLocalizationContext } from "../../context/LocalizationContext";
import { useGetOverview } from "../api/useGetOverview";
import { useNotification } from "../hooks/useNotification";

export default memo(function Overview() {
  const { t } = useLocalizationContext();
  // เรียก api get overview ที่นี่แล้วเอาตัวแปรไปแทนตัวเลข

  const { data: overview, isLoading, error } = useGetOverview(); // student id

  const { notiSystemError } = useNotification();

  useEffect(() => {
    // ถ้ามี error จาก api /overview ให้ขึ้น noti system error
    if (error && !isLoading) {
      notiSystemError();
    }
  }, [error, t, isLoading, notiSystemError]);

  return (
    <Row gutter={[16, 16]}>
      <Col xs={24} sm={24} md={8} lg={8} xl={8}>
        <Card bodyStyle={{ padding: "16px" }} style={{ height: "100%" }}>
          <Box
            direction="column"
            justify="space-between"
            style={{ height: "100%" }}
          >
            <Text
              type="form title"
              text={t("LABEL.totalRequest")} // หัวข้อจำนวน request ทั้งหมด
            />
            <Text
              type="header"
              text={overview?.total ? overview.total : 0} // จำนวน request ทั้งหมด
            />
            {/* เลข 10 ให้แทนที่ด้วยตัวแปรเดียวกับ จำนวน request ทั้งหมด */}
            <Text type="normal bold" text={t("LABEL.request", { count: 10 })} />
          </Box>
        </Card>
      </Col>
      <Col xs={24} sm={24} md={8} lg={8} xl={8}>
        <Card bodyStyle={{ padding: "16px" }} style={{ height: "100%" }}>
          <Box
            direction="column"
            justify="space-between"
            style={{ height: "100%" }}
          >
            <Text
              type="form title"
              text={t("LABEL.totalRequestByMajor")} // หัวข้อจำนวน request ทั้งหมดในแต่ละสาขา
            />
            <Box gap={16}>
              <Box direction="column">
                <Text
                  type="normal"
                  text={`• ${t("LABEL.se_m")}: ${overview?.se_m ? overview.se_m : 0}`} // จำนวน request ทั้งหมดของ SE Master
                />
                <Text
                  type="normal"
                  text={`• ${t("LABEL.kim_m")}: ${overview?.kim_m ? overview.kim_m : 0}`} // จำนวน request ทั้งหมดของ KIM Master
                />
                <Text
                  type="normal"
                  text={`• ${t("LABEL.kim_d")}: ${overview?.kim_d ? overview.kim_d : 0}`} // จำนวน request ทั้งหมดของ KIM Doctoral
                />
                <Text
                  type="normal"
                  text={`• ${t("LABEL.dtm_m")}: ${overview?.dtm_m ? overview.dtm_m : 0}`} // จำนวน request ทั้งหมดของ DTM Master
                />
              </Box>
              {/* Box ตัวนี้ใช้สำหรับขึ้น column ใหม่ถ้าอยากให้เรียงลงมาเรื่อยๆให้เอา Box นี้ออก */}
              {/* <Box direction="column">
                <Text
                  type="normal"
                  text={`${t("LABEL.dtm_m")}: ${overview?.dtm ? overview.dtm_m : 0}`} // จำนวน request ทั้งหมดของ DTM Master
                />
                <Text
                  type="normal"
                  text={`DG: ${5}`} // จำนวน request ทั้งหมดของ dg
                />
              </Box> */}
              {/* ------------------------------------------------------------- */}
            </Box>
          </Box>
        </Card>
      </Col>
      <Col xs={24} sm={24} md={8} lg={8} xl={8}>
        <Card bodyStyle={{ padding: "16px" }} style={{ height: "100%" }}>
          <Box
            direction="column"
            justify="space-between"
            style={{ height: "100%" }}
          >
            <Text
              type="form title"
              text={t("LABEL.totalRequestByStatus")} // หัวข้อจำนวน request ทั้งหมดในแต่ละสถานะ
            />
            <Box direction="column">
              <Text
                type="normal"
                text={`• ${t("LABEL.approved")}: ${overview?.approved ? overview.approved : 0}`} // จำนวน request ทั้งหมดของ approve
              />
              <Text
                type="normal"
                text={`• ${t("LABEL.rejected")}: ${overview?.rejected ? overview.rejected : 0}`} // จำนวน request ทั้งหมดของ reject
              />
              <Text
                type="normal"
                text={`• ${t("LABEL.pending")}: ${overview?.pending ? overview.pending : 0}`} // จำนวน request ทั้งหมดของ pending
              />
            </Box>
          </Box>
        </Card>
      </Col>
    </Row>
  );
});
