import { Col, DatePicker, Form, Radio, Row } from "antd";
import { memo } from "react";
import { Text } from "../../DesignSystem";
import { useLocalizationContext } from "../../../context/LocalizationContext";
import { ForeignResearcherTable } from "./ForeignResearcherTable";
import { useFieldValidate } from "../../hooks/useFieldValidate";

export const QuestionForm = memo(({ isJournal, form }) => {
  // render question form ส่วนที่เป็น yes no
  const { t } = useLocalizationContext();
  const { requireSelect } = useFieldValidate();

  const isHasforeign = Form.useWatch("hasforeign", form) === "true";
  const isAccept = Form.useWatch("isjaccepted", form) === "true";

  return (
    <Row>
      {/* ----------- ส่วนที่ให้เลือก isfirst --------------  */}
      <Col span={24}>
        <Form.Item
          name="isfirst"
          label={
            <Text
              type="normal bold"
              text={`4.2 `+ t("LABEL.first_or_not_first_author")}
            />
          }
          required={false}
          rules={[requireSelect]}
        >
          <Radio.Group>
            <Radio value="true">
              <Text type="normal bold" text={t("LABEL.yes")} />
            </Radio>
            <Radio value="false">
              <Text type="normal bold" text={t("LABEL.no")} />
            </Radio>
          </Radio.Group>
        </Form.Item>
      </Col>
      {/* -------------------------------------------- */}

      {/* ------------------------------ ส่วนที่ให้เลือก hasadv ---------------------------- */}
      <Col span={24}>
        <Form.Item
          name="hasadv"
          label={
            <Text
              type="normal bold"
              text={`4.3 `+ t("LABEL.has_proceeding_advisor_or_no")}
            />
          }
          required={false}
          rules={[requireSelect]}
        >
          <Radio.Group>
            <Radio value="true">
              <Text type="normal bold" text={t("LABEL.yes")} />
            </Radio>
            <Radio value="false">
              <Text type="normal bold" text={t("LABEL.no")} />
            </Radio>
          </Radio.Group>
        </Form.Item>
      </Col>
      {/* ------------------------------------------------------------------- */}

      {/* ------- ส่วนที่ให้เลือก hasforeign ------------- */}
      <Col span={24}>
        <Form.Item
          name="hasforeign"
          label={
            <Text
              type="normal bold"
              text={`4.4 `+ t("LABEL.proposed_academic_works")}
            />
          }
          required={false}
          rules={[requireSelect]}
        >
          <Radio.Group>
            <Radio value="true">
              <Text type="normal bold" text={t("LABEL.yes")} />
            </Radio>
            <Radio value="false">
              <Text type="normal bold" text={t("LABEL.no")} />
            </Radio>
          </Radio.Group>
        </Form.Item>
      </Col>
      {/* ---------------------------------------------- */}

      {/* ---------- ตาราง hasforeign ------------------- */}
      {isHasforeign && <ForeignResearcherTable />}
      {/* --------------------------------------------- */}

      {/* --------- ถ้าเป็น journal จะมี isjaccepted ให้เลือก -------------- */}
      {isJournal && (
        <Col span={24} style={{ marginTop: isHasforeign ? 24 : 0 }}>
          <Form.Item
            name="isjaccepted"
            label={
              <Text
                type="normal bold"
                text={`4.4 `+ t("LABEL.accepted_or_published")}
              />
            }
            required={false}
            rules={[requireSelect]}
            style={{ marginBottom: isAccept ? 8 : 24 }}
          >
            <Radio.Group>
              <Radio value="true">
                <Text type="normal bold" text={t("LABEL.accepted")} />
              </Radio>
              <Radio value="false">
                <Text type="normal bold" text={t("LABEL.published")} />
              </Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
      )}
      {/* --------------------------------------------------- */}

      {/* ----------- ถ้าเลือก accept จะมีวันที่ให้เลือก ------------- */}
      {isAccept && (
        <Form.Item
          name="jaccepteddate"
          label={<Text type="normal bold" text={t("LABEL.select_date")} />}
        >
          <DatePicker />
        </Form.Item>
      )}
      {/* ---------------------------------------------------- */}
    </Row>
  );
});
