import { useLocalizationContext } from "../../context/LocalizationContext";

export const useSelectOption = () => {
  // ใช้รวม option ใน dropdown
  const { t } = useLocalizationContext();

  const searchOptions = [
    // option สำหรับ search type
    { value: "year", label: t("LABEL.year") },
    { value: "aname", label: t("LABEL.advisor_name") },
    { value: "sname", label: t("LABEL.student_name") },
    { value: "sid", label: t("LABEL.student_identify") },
  ];

  const formTypeOptions = [
    // option สำหรับ filter pub type
    { value: "", label: t("LABEL.all") },
    { value: "CON", label: t("LABEL.conferences") },
    { value: "JOU", label: t("LABEL.journal") },
    { value: "OTH", label: t("LABEL.others") },
  ];

  const formMajorOptions = [
    // option สำหรับ filter major
    { value: "", label: t("LABEL.all") },
    { value: "SE_M", label: t("LABEL.se_m") },
    { value: "KIM_M", label: t("LABEL.kim_m") },
    { value: "KIM_D", label: t("LABEL.kim_d") },
    { value: "DTM_M", label: t("LABEL.dtm_m") },
  ];

  const statusOptions = [
    // option สำหรับ filter status
    { value: "", label: t("LABEL.all") },
    { value: "APPROVED", label: t("LABEL.approved") },
    { value: "REJECTED", label: t("LABEL.rejected") },
    { value: "PENDING", label: t("LABEL.pending") },
  ];

  const majorOptions = [
    // option สำหรับ major
    { value: "SE_M", label: t("LABEL.se_m") },
    { value: "KIM_M", label: t("LABEL.kim_m") },
    { value: "KIM_D", label: t("LABEL.kim_d") },
    { value: "DTM_M", label: t("LABEL.dtm_m") },
  ];

  const planOptions = [
    // option สำหรับ edu plan
    { value: "T1", label: t("LABEL.t1") },
    { value: "T2", label: t("LABEL.t2") },
    { value: "T3", label: t("LABEL.t3") },
    { value: "T1_1", label: t("LABEL.t1_1") },
    { value: "T1_2", label: t("LABEL.t1_2") },
    { value: "T2_1", label: t("LABEL.t2_1") },
    { value: "T2_2", label: t("LABEL.t2_2") },
  ];

  return {
    searchOptions,
    formTypeOptions,
    formMajorOptions,
    statusOptions,
    majorOptions,
    planOptions,
  };
};
