import useSWR from "swr";

export const useGetOverview = () => {
  // api get overview
  let { data, error } = useSWR(`/api/overview`, {
    shouldRetryOnError: false,
  });
  if (error) console.log(error);

  // data = {
  //   total: 100,
  //   se: 12,
  //   kim_m: 11,
  //   kim_d: 13,
  //   dtm_m: 7,
  //   approved: 21,
  //   rejected: 8,
  //   pending: 12,
  // };
  // console.log(data);

  return {
    data,
    isLoading: !data && !error,
    error,
  };
};
