import { memo, useMemo } from "react";
import { useDefaultFormLabel } from "../../hooks/useDefaultFormLabel";
import { Col, Form, Input, Row } from "antd";
import { Text } from "../../DesignSystem";
import { useFieldValidate } from "../../hooks/useFieldValidate";
import { useLocalizationContext } from "../../../context/LocalizationContext";

export const JournalForm = memo(({ jtype }) => {
  // render journal form
  const { DEFAULT_JOURNAL_FORM } = useDefaultFormLabel();
  const { requireField } = useFieldValidate();
  const { t } = useLocalizationContext();

  const isNational = jtype === "NAT"; // เช็คว่าเป็น nat รึป่าว

  const index = 0;

  const formData = useMemo(() => {
    // เช็ค form display ถ้าเป็น nat จะใช้ form เดิมแต่ถ้าเป็น inat จะเอา field ttitle  ออก
    return isNational
      ? DEFAULT_JOURNAL_FORM
      : DEFAULT_JOURNAL_FORM?.filter((item) => item?.key !== "ttitle");
  }, [DEFAULT_JOURNAL_FORM, isNational]);

  function runningNumber(key) {
    switch (key) {
      case "etitle":
        return 2;
      case "author":
        return 3;
      case "aname":
        return 4;
      case "anameco_1":
        return 5;
      default:
        return 0; // Default value if the input doesn't match any case
    }
  }

  return (
    <Row gutter={24} style={{ width: "100%" }}>
      {formData.map((item, index) => {
        return (
          <Col // เช็ค column responsive โดย 1 row จะเต็มที่ 24
            key={item?.key}
            xs={24} // จอขนาด xs คือเล็กที่สุดจะขึ้นแค่ 1 row ต่อ 1 field
            sm={24} // ขึ้นแค่ 1 row ต่อ 1 field
            // เช็คถ้าเป็น inat และเป็น field etitle จะขึ้นแค่ 1 row ก็คือแสดงแค่ field เดียวในแถวนั้นแต่ถ้าไม่ใช่จะแสดงอย่างละครึ่ง
            md={!isNational && index === 0 ? 24 : 12}
            lg={!isNational && index === 0 ? 24 : 12}
            xl={!isNational && index === 0 ? 24 : 12}
          >
            <Form.Item
              required={false}
              name={item?.key}
              label={
                <Text
                  type="normal bold"
                  text={
                    item?.key !== "ttitle" && item?.key !== "anameco_2"
                      ? `3.` +
                        runningNumber(item?.key) +
                        " " +
                        (item?.key !== "etitle"
                          ? item?.label
                          : t("LABEL.title_in_english_and_thai"))
                      : ""
                  }
                />
              }
              rules={[!item?.key.includes("anameco")? requireField : null]}
            >
              <Input placeholder={item?.label} />
            </Form.Item>
          </Col>
        );
      })}
    </Row>
  );
});
