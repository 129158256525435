import axios from "axios";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useSearchParams } from "react-router-dom";

const UserDataContext = createContext(null);

const CAMT_ID = "21";

function UserDataProvider({ children }) {
  // เอาไว้เป็นตัวกลางที่จัดการข้อมูล user
  const [userState, setUserState] = useState(null);

  const isInit = useRef(false);
  const isInitToken = useRef(false);

  const [searchParams] = useSearchParams();
  const userCode = searchParams.get("code");

  const token = localStorage.getItem("token");
  const userData = localStorage.getItem("user");

  const handleLogout = () => {
    localStorage.clear();
    window.location.href = "https://pandit.camt.cmu.ac.th/";
  };

  useEffect(() => {
    // ถ้าไม่มี code เด้งออก
    if (!token && !userCode) {
      handleLogout();
    }
  }, [userCode, token]);

  const getToken = useCallback(async () => {
    // get token
    const { data: res, error } = await axios.get(
      `${process.env.REACT_APP_API_PATH}/api/token?code=${userCode}`
    );
    if (res && !error) {
      // ถ้ามีข้อมูลและไม่มี error
      const isCamt = res?.data?.organization?.code === CAMT_ID;
      if (isCamt) {
        // ถ้าเป็นคนของ camt หรือ admin ให้ set localStorage
        localStorage.setItem("token", res?.token);
        localStorage.setItem("user", JSON.stringify(res?.data));
        setUserState(res?.data);
      } else {
        // ถ้าไม่ใช่คนของ camt เด้งออก
        handleLogout();
      }
    }
    if (error && !res) {
      // ถ้ามี error และไม่มีข้อมูลให้เด้งออก
      handleLogout();
    }
  }, [userCode]);

  useEffect(() => {
    // init ข้อมูลถ้ามี code ให้ยิง get token
    if (!token && userCode && !isInitToken.current) {
      getToken();
    }

    return () => {
      isInitToken.current = true;
    };
  }, [getToken, token, userCode]);

  useEffect(() => {
    // ถ้ามี userData ใน storage ให้ set ข้อมูล userState
    if (userData && !isInit.current) {
      setUserState(JSON.parse(userData));
      isInit.current = true;
    }
  }, [userData]);

  const value = {
    userData: userState,
    isStudent: userState?.itaccount_type?.id === "StdAcc",
    handleLogout,
  };

  return (
    <UserDataContext.Provider value={value}>
      {children}
    </UserDataContext.Provider>
  );
}

function useUserDataContext() {
  const context = useContext(UserDataContext);
  if (context === undefined) {
    throw new Error("Error context undefined");
  }
  return context;
}

export { UserDataProvider, useUserDataContext };
