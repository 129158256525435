import { Button, Typography } from "antd";
import styled, { css } from "styled-components";

// ไฟล์สำหรับรวม style กลางที่ใช้ในระบบ

const TypographyStyle = styled(Typography)`
  color: ${({ color }) => color || "var(--black-200)"};
  margin-bottom: 0px;
  ${({ type }) => {
    if (type === "header") {
      return css`
        font-size: 24px;
        font-weight: 600;
      `;
    }
    if (type === "card title") {
      return css`
        font-size: 20px;
        font-weight: 600;
      `;
    }
    if (type === "form title") {
      return css`
        font-size: 18px;
        font-weight: 500;
      `;
    }
    if (type === "normal bold") {
      return css`
        font-size: 16px;
        font-weight: 600;
      `;
    }
    if (type === "normal") {
      return css`
        font-size: 16px;
        font-weight: 400;
      `;
    }
    if (type === "normal gray") {
      return css`
        font-size: 16px;
        font-weight: 400;
        color: var(--gray-300);
      `;
    }
    if (type === "small") {
      return css`
        font-size: 14px;
        font-weight: 400;
      `;
    }
    if (type === "link") {
      return css`
        font-size: 16px;
        font-weight: 400;
        color: var(--blue);
        text-decoration: underline;
        cursor: pointer;
      `;
    }
  }}
`;

// Text ใช้สำหรับแสดงตัวหนังสือ
export const Text = ({ type, text, style, color, onClick }) => (
  <TypographyStyle type={type} style={style} color={color} onClick={onClick}>
    {text}
  </TypographyStyle>
);


// ใช้สำหรับแสดงปุ่ม
export const BaseButton = styled(Button)`
  font-weight: 500;
  &.ant-btn-primary {
    background-color: ${({ danger }) =>
      danger ? "var(--main-red)" : "var(--main-orange)"};
  }
  &.ant-btn-primary:hover {
    background-color: ${({ danger }) =>
    danger ? "var(--main-red)" : "var(--main-orange)"};
  }
  &.ant-btn-primary:disabled {
    background-color: rgba(0, 0, 0, 0.04);
  }
`;

// เป็น div ตัวหนึ่ง
export const Box = styled.div`
  display: flex;
  justify-content: ${({ justify }) => justify};
  align-items: ${({ align }) => align};
  flex-direction: ${({ direction }) => direction};
  gap: ${({ gap }) => `${gap}px`};
  padding: ${({ padding }) => padding};
  margin: ${({ margin }) => margin};
`;
