import { useLocalizationContext } from "../../context/LocalizationContext";

export const useDefaultFormLabel = () => {
  // ใช้สำหรับกำหนด default ของ form ว่าจะให้มี field อะไรบ้าง
  const { t } = useLocalizationContext();

  const DEFAULT_STUDENT_INFO_FORM = [
    // ส่วนของ student form
    {
      key: "sid", // รหัสนักศึกษา
      label: `1.1 `+ t("LABEL.student_identify"), // label คือหัวข้อที่จะใช้แสดงผล
      disabled: true, // disabled คือจะให้ช่อง input นี้ปิดไม่ให้กรอกหรือไม่
    },
    {
      key: "sname", // ชื่อนักศึกษา
      label: `1.2 `+ t("LABEL.student_fullname"),
      disabled: true,
    },
    {
      key: "semail", // email นักศึกษา
      label: `1.3 `+ t("LABEL.student_email"),
      disabled: true,
    },
    {
      key: "smajor", // สาขา
      label: `1.4 `+ t("LABEL.major_field_of_specialization"),
      disabled: false,
      type: "dropdown"
    },
    {
      key: "seduplan", // แผนการเรียน
      label: `1.5 `+ t("LABEL.education_plan"),
      disabled: false,
      type: "dropdown"
    },
    {
      key: "stelno", // เบอร์โทรศัพท์นักศึกษา
      label: `1.6 `+ t("LABEL.student_tel_no"),
      disabled: false,
    },
  ];

  const DEFAULT_CONFERENCES_FORM = [
    // ส่วนของ conferences form
    {
      key: "etitle",
      label: t("LABEL.title_in_english"),
      type: "input",
    },
    {
      key: "ttitle", // key เอาไว้อ้างอิงเวลากรอก value จะเข้าไปที่ field key นี้
      label: t("LABEL.title_in_thai"), // label คือหัวข้อที่จะใช้แสดงผล
      type: "input", // type คือเอาไว้บอกตอนที่เอาไป map ว่าถ้า type นี้จะใช้ component อะไร input ก็คือแสดงช่อง input ธรรมดา
    },
    {
      key: "author",
      label: t("LABEL.student_presentations"),
      type: "input",
    },
    {
      key: "aname",
      label: t("LABEL.advisor_name"),
      type: "input",
    },
    {
      key: "anameco_1",
      label: t("LABEL.advisor_name_co"),
      type: "input",
    },
    {
      key: "anameco_2",
      label: t("LABEL.advisor_name_co"),
      type: "input",
    },
    {
      key: "conname",
      label: t("LABEL.conferences_name"),
      type: "input",
    },
    {
      key: "condestination",
      label: t("LABEL.conferences_destination"),
      type: "input",
    },
    {
      key: "condate",
      label: t("LABEL.conferences_date"),
      type: "date", // date คือแสดง component ที่เป็น date picker
    },
  ];

  const DEFAULT_JOURNAL_FORM = [ // journal form 
    {
      key: "etitle",
      label: t("LABEL.title_in_english"),
      type: "input",
    },
    {
      key: "ttitle",
      label: t("LABEL.title_in_thai"),
      type: "input",
    },
    {
      key: "author",
      label: t("LABEL.author_name"),
      type: "input",
    },
    {
      key: "aname",
      label: t("LABEL.advisor_name"),
      type: "input",
    },
    {
      key: "anameco_1",
      label: t("LABEL.advisor_name_co"),
      type: "input",
    },
    {
      key: "anameco_2",
      label: t("LABEL.advisor_name_co"),
      type: "input",
    },
  ];

  const DEFAULT_JOURNAL_QUESTION_FORM = [ // journal question form คำถามส่วนเสริมต่างๆของ journal
    {
      key: "jname",
      label: t("LABEL.journal_name"),
      type: "input",
    },
    {
      key: "jvol",
      label: t("LABEL.volume"),
      type: "number", // number คือแสดง component input number
    },
    {
      key: "jno",
      label: t("LABEL.no_issue"),
      type: "input",
    },
    {
      key: "jyear",
      label: t("LABEL.journal_year"),
      type: "input",
    },
    {
      key: "jpage",
      label: t("LABEL.pages"),
      type: "input",
    },
    {
      key: "jsubmitdate",
      label: t("LABEL.submit_date"),
      type: "date",
    },
    // {
    //   key: "jimpfscore",
    //   label: `3.14 `+ t("LABEL.impact_factor"),
    //   type: "inputDecimal", // inputDecimal คือแสดง component input number ที่กรอกเป็นทศนิยมเท่านั้น
    // },
    // {
    //   key: "jimpfdate",
    //   label: `3.15 `+ t("LABEL.impact_factor_date"),
    //   type: "date",
    // },
  ];

  return {
    DEFAULT_STUDENT_INFO_FORM,
    DEFAULT_CONFERENCES_FORM,
    DEFAULT_JOURNAL_FORM,
    DEFAULT_JOURNAL_QUESTION_FORM,
  };
};
